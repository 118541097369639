import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { LoginRequest } from '../../../core/models/loginRequest';
import { HttpClient } from '@angular/common/http';
import { catchError, take, tap } from 'rxjs/operators';

export const IS_AUTHORIZED = 'PMD_IS_AUTHORIZED';

@Injectable({providedIn: 'root'})
export class AuthService {
  public logout$: Subject<boolean> = new Subject<boolean>();
  public login$: Subject<boolean> = new Subject<boolean>();

  public readonly INITIAL_PATH = '/day-editor';

  private readonly IS_AUTHORIZED = IS_AUTHORIZED;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  public isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem(this.IS_AUTHORIZED));
  }

  public isLoggedIn$(): Observable<boolean> {
    return of(JSON.parse(localStorage.getItem(this.IS_AUTHORIZED)));
  }

  public get getUser() {
    return null;
  }

  public login(loginRequest: LoginRequest): Observable<any> {
    return this.http.post('/auth/login', loginRequest)
      .pipe(
        take(1),
        tap(() => localStorage.setItem(this.IS_AUTHORIZED, JSON.stringify(true))));
  }

  public register(registerRequest): Observable<any> {
    return this.http.post('/auth/register', registerRequest);
  }

  public logout() {
    return this.http.post('/auth/logout', {})
      .pipe(
        tap(() => {
            this._clearAuthStorages();
          },
        ),
        catchError((error) => {
          this._clearAuthStorages();
          return throwError(() => new Error(error));
        }));
  }

  public resetPassword(): Observable<Object>  {
    return this.http.post('/auth/reset-password', {});
  }

  private _clearAuthStorages(): void {
    localStorage.removeItem(this.IS_AUTHORIZED);
    this.logout$.next(null);
    this.router.navigate(['/login']).then();
  }
}
