import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class GenerateDefaultActivityForTaskGQL extends Mutation {
  document = gql`
    mutation GenerateDefaultActivityForTaskGQL($task_id: uuid, $description: String!, $duration: Int!, $priority_id: bigint!, $order_in_clipboard: Int) {
      insert_activities_one(object: {task_id: $task_id, in_clipboard: true, description: $description, duration: $duration, priorityId: $priority_id, order_in_clipboard: $order_in_clipboard, order_in_phase: null}) {
        id
      }
    }
  `;
}
