import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CloneTemplateGQL extends Mutation {
  document = gql`
    mutation cloneTemplate($id: BigInt!, $name: String!) {
      cloneTemplate(id: $id, name: $name) {
        template {
          id
          name
          active
          default
        }
      }
    }`;
}
