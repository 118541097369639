import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GraphqlService } from '../../../core/services/graphql.service';
import { GoogleSigninService } from './google-signin.service';
import { Appointment } from '../../../core/models/interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { selectAllActivities } from '../../../store/activities/activities.selectors';

export interface Calendar {
  id: string;
  summary: string;
  summaryOverride?: string;
  enabled: boolean;
  configured: boolean;
}

@Injectable({providedIn: 'root'})
export class GoogleCalendarService {
  public calendars: Calendar[] | undefined;

  constructor(
    private _store: Store<AppState>,
    private googleSigninService: GoogleSigninService,
    private graphqlService: GraphqlService,
  ) {
    this.googleSigninService.observable().subscribe((user) => {
      if (!user) {
        this.calendars = undefined;
      }
    });
  }

  prepareAllGoogleCalendars() {
    return new Promise(async (resolve) => {
      await this.googleSigninService.signIn();
      gapi.client.calendar.calendarList.list({
        maxResults: 20,
      }).then((response) => {
        resolve(response.result);

        this.calendars = [];
        for (const cal of response.result.items) {
          this.calendars.push({
            id: cal.id,
            summary: cal.summary,
            summaryOverride: cal.summaryOverride,
            enabled: false,
            configured: false,
          });
        }
      });
    });
  }

  /**
   * merges the user specific config of selected google calendars
   * with the available google account calendars
   * @returns
   */
  public getGoogleCalendarList(): Observable<Calendar[]> {
    return this.graphqlService.getCalendarSelectionConfig().pipe(
      map((result) => {
        const calendarsConfig = result.data.calendar_selection_config;
        return this.calendars.map((calendar) => {
          const calendarFromConfig = calendarsConfig.find((confCalendar) => confCalendar.calendar_id === calendar.id);
          if (calendarFromConfig) {
            calendar.enabled = calendarFromConfig.enabled;
            calendar.configured = true;
          }
          return calendar;
        });
      }),
    );
  }

  public getEventsForDate(calId: string, date: string): Promise<any> {
    const datetime = DateTime.fromSQL(date);
    const timeMin = datetime.toISO({suppressMilliseconds: true});
    const timeMax = datetime.plus({days: 1}).toISO({suppressMilliseconds: true});

    return new Promise(async (resolve) => {
      await this.googleSigninService.signIn();
      gapi.client.calendar.events.list({
        calendarId: calId,
        maxResults: 20,
        singleEvents: true,
        orderBy: 'startTime',
        timeMin,
        timeMax,
      }).then((response) => {
        const events = [];
        for (const item of response.result.items) {
          let activities = [];
          this._store.select(selectAllActivities).pipe(take(1)).subscribe((acts) => activities = acts);

          const appointment: Appointment = {
            extId: item.id,
            extLink: item.htmlLink,
            summary: item.summary,
            startTime: this.convertTime(item.start),
            endTime: this.convertTime(item.end),
            allDay: !!item.start.date,
            provider: 'google',
            planned: activities.some((act) => act.appointment ? act.appointment.extId === item.id : false),
          };
          events.push(appointment);
        }

        resolve(events);
      });
    });
  }

  private convertTime(timeObject): DateTime | undefined {
    let time: DateTime | undefined;
    if (timeObject.date !== undefined) {
      time = DateTime.fromSQL(timeObject.date);
    } else if (timeObject.dateTime !== undefined) {
      time = DateTime.fromISO(timeObject.dateTime);
    }
    return time;
  }

  selectCalendar(id: string): Observable<any> {
    const cal = this.calendars.find((el) => el.id === id);
    if (!!this.calendars && cal) {
      return cal.configured
        ? this.graphqlService.updateCalendarInConfig(cal.id, cal.enabled)
        : this.graphqlService.insertCalendarToConfig(cal.id, cal.enabled);
    } else {
      return of(null);
    }
  }

  /**
   * requesting the api for user specific configuration of google calendars
   * @returns configuration that inidcates selection of
   *          enabled google calendars for the user
   */
}
