import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class InsertNewDayFromTemplateGQL extends Mutation {
  document = gql`
    mutation addNewDayFromTemplate ($templateId: BigInt!, $date: ISO8601Date!) {
        addNewDayFromTemplate (templateId: $templateId, date: $date)
        {
            activities {
                id
            }
            errors
        }
    }`;
}
