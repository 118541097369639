<div [matMenuTriggerFor]="menu" class="w-full flex items-center justify-between">
  <span><ng-content></ng-content></span>
  <mat-icon svgIcon="icon-flag-{{currentLang$ | async}}"></mat-icon>
</div>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let lang of LANGUAGES | keyvalue:(originalSort)">
    <button (click)="setLanguage(lang.value.id)" mat-menu-item>
      <mat-icon svgIcon="icon-flag-{{lang.value.id}}"></mat-icon>
      <span>{{lang.value.name}}</span>
    </button>
  </ng-container>
</mat-menu>
