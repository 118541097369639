import { ActionReducerMap } from '@ngrx/store';
import { ActivitiesState, clipboardActivitiesStoreReducer } from './clipboard-activities/clipboard-activities.reducer';
import { ClipboardActivitiesEffect } from './clipboard-activities/clipboard-activities.effects';
import { PrioritiesState, prioritiesStoreReducer } from './priorities/priorities.reducer';
import { PrioritiesEffect } from './priorities/priorities.effects';
import { PhaseTemplatesState, phaseTemplatesStoreReducer } from './phase-templates/phase-templates.reducer';
import { PhaseTemplatesEffect } from './phase-templates/phase-templates.effects';
import { activitiesStoreReducer } from './activities/activities.reducer';
import { ActivitiesEffect } from './activities/activities.effects';

/*
* Intarface for all entities of state
*/
export interface AppState {
  clipboardActivities: ActivitiesState;
  priorities: PrioritiesState;
  phaseTemplates: PhaseTemplatesState;
  activities: ActivitiesState;
}

export const reducers: ActionReducerMap<AppState> = {
  clipboardActivities: clipboardActivitiesStoreReducer,
  priorities: prioritiesStoreReducer,
  phaseTemplates: phaseTemplatesStoreReducer,
  activities: activitiesStoreReducer,
};

export const storeEffects = [
  ClipboardActivitiesEffect,
  PrioritiesEffect,
  PhaseTemplatesEffect,
  ActivitiesEffect,
];
