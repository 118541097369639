import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NotificationService } from '../../core/services/notification.service';
import { IS_AUTHORIZED } from '../../modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { LOGIN_URL } from '../../_helpers/constants.helper';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private _notificationService: NotificationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          console.error(error);

          if (error.status === 401 || error.status === 440) {
            localStorage.removeItem(IS_AUTHORIZED);
            window.location.assign(`${environment.frontendHost}${LOGIN_URL}redirect_uri=${window.location.origin}/app`);
            return throwError(() => new Error(`Error: ${error.error.message}`));
          }

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.error.message) {
              errorMessage = errorMessage + `\n${error.error.message}`;
            }
          }
          this._notificationService.error({title: 'HTTP Error!', description: errorMessage});
          return throwError(() => new Error(errorMessage));
        },
      ),
    );
  }
}
