import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FetchActivitiesInClipboardCountForTaskByActivityGQL extends Query<any> {
  document = gql`
  query FetchActivitiesInClipboardCountForTaskByActivity($activity_id: bigint) {
    activities_aggregate(where: {_and: {task: {activities: {id: {_eq: $activity_id}}}, in_clipboard: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    tasks(where: {activities: {id: {_eq: $activity_id}}}) {
      group {
        id
      }
      id
    }
  }
  `;
}
