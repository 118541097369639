import { createAction, props } from '@ngrx/store';
import { Activity } from '../../core/models/interfaces';
import { Update } from '@ngrx/entity';
import { ToastTypes } from '../../core/services/notification.service';

// load
export const loadeActivitiesTreeAction = createAction('[ClipboardActivities] Load Activities Tree');
export const loadActivitiesTreeSuccessAction = createAction('[ClipboardActivities] Load Activities Success', props<{ responce: Activity[] }>());

// update
export const updateTaskActivityInlipboardAction = createAction('[ClipboardActivities] Update Task Activity In lipboard', props<{ oldActivity: Activity; activity: Activity; msg: string; }>());
export const updateActivityInStoreAction = createAction('[ClipboardActivities] Update Activity In Store', props<{ update: Update<Activity> }>());
export const updateActivityAction = createAction('[ClipboardActivities] Update Activity with Effect', props<{ activity: Activity; msg: string; }>());
export const updateActivityPriorityAction = createAction('[ClipboardActivities] Update Activity Priority', props<{ changes: { activityId: number; priorityId: number; }; msg: string; }>());

// add
export const addActivityToClipboardAction = createAction('[ClipboardActivities] Add Activity', props<{ activity: Activity; msg: string; }>());
export const addActivityToClipboardStoreAction = createAction('[ClipboardActivities] Add Activity To Clipboard Store', props<{ activity: Activity; }>());

// delete
export const deleteActivityFromClipboardAction = createAction('[ClipboardActivities] Delete Activity From Clipboard', props<{ activity: Activity; msg: string; }>());
export const deleteActivityFromClipboardStoreAction = createAction('[ClipboardActivities] Delete Activity From Store', props<{ id: number }>());

// extra actions
export const sortDayFromClipboardStoreAction = createAction('[ClipboardActivities] Sort Day From Clipboard Store', props<{ date: any; templateId: number; msg: string; }>());
export const showToastClipboardAction = createAction('[ClipboardActivities] Show Toast Clipboard Activities', props<{ toastType: ToastTypes; errorMsg: string; }>());
