import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class FinishExplicitTaskGQL extends Mutation {
  document = gql`
  mutation FINISH_EXPLICIT_TASK_GQL(
    $id: String!,
    $unfinished_planned_activities: String!,
    $unfinished_unplanned_activities: String!
  ) {
    finishExplicitTask(
      unfinishedPlannedActivities: $unfinished_planned_activities,
      unfinishedUnplannedActivities: $unfinished_unplanned_activities,
      id: $id
    ) {
      errors
      changedActivities {
        id
        done
        description
        date
      }
      task {
        id
        description
        status
      }
    }
  }`;
}
