import { Inject, Injectable, Injector } from '@angular/core';
import { HotToastService, ToastOptions } from '@ngneat/hot-toast';
import { CommonToastComponent } from '../../shared/components/toasts/common-toast/common-toast.component';
import { CommonToastData } from '../../shared/components/toasts/common-toast/common-toast.model';

interface ToastOptionsData {
  title: string;
  description: any;
  btnCaption: any;
  func: () => any;
}

export type ToastTypes = 'success' | 'error' | 'warning' | 'primary';

@Injectable({providedIn: 'root'})
export class NotificationService {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
  ) {
  }

  get toast() {
    return this.injector.get(HotToastService);
  }

  success(data: CommonToastData, options?: {autoClose?: boolean, icon?: string}) {
    this.toast.success(CommonToastComponent, this.prepareOptions(data, 'hot-toast-bg-success', '😌️', options));
  }

  warning(data: CommonToastData, options?: {autoClose?: boolean, icon?: string}) {
    this.toast.warning(CommonToastComponent, this.prepareOptions(data, 'hot-toast-bg-warning', '⚠️', options));
  }

  error(data: CommonToastData, options?: {autoClose?: boolean, icon?: string}) {
    this.toast.error(CommonToastComponent, this.prepareOptions(data, 'hot-toast-bg-error', '😔', options));
  }

  primary(data: CommonToastData, options?: {autoClose?: boolean, icon?: string}) {
    this.toast.show(CommonToastComponent, this.prepareOptions(data, 'hot-toast-bg-primary', 'ℹ️', options));
  }

  prepareOptions(data: CommonToastData, className: string, icon: string, options?: {autoClose?: boolean, icon?: string}): ToastOptions<ToastOptionsData> {
    return {
      data: {
        title: data?.title,
        description: data.description,
        btnCaption: data.btnCaption,
        func: () => data.func(),
      },
      icon: options?.icon || icon || null,
      autoClose: options?.autoClose !== undefined ? options.autoClose : true,
      className: className,
      position: 'top-right',
    };
  }
}
