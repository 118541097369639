/* eslint-disable no-extra-parens */
import { Injectable, Signal } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { GraphqlService } from './graphql.service';
import { Activity, ChangedActivitiesResp, Phase } from '../models/interfaces';
import { DateTime } from 'luxon';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { signalState, patchState } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import {
  addActivityInStoreAction,
  deleteActivitiesByIdsAction,
  deleteActivitiesByPredicateAction,
  updateManyActivitiesInStoreAction,
} from '../../store/activities/activities.actions';
import { PhaseSorter } from '../../_helpers/phase-sorter';
import { selectAllActivities } from '../../store/activities/activities.selectors';
import { NgProgressService } from '../../shared/services/ng-progress.service';
import { ConfirmationService } from './confirmation.service';
import { Update } from '@ngrx/entity';

type PhasesState = { phases: Phase[]; };

@Injectable({providedIn: 'root'})
export class PhasesService {
  // private signalState for phases
  readonly #state = signalState<PhasesState>({ phases: [] });
  // private Map for phases sorted by days
  readonly #daysMap: Map<string, Phase[]> = new Map();

  public readonly allPhaseByDaySignal: Signal<Phase[]> = this.#state.phases;
  public onRefreshDays$: Subject<void> = new Subject<void>();

  constructor(
    private _store: Store<AppState>,
    private _graphqlService: GraphqlService,
    private _notificationService: NotificationService,
    private _translate: TranslateService,
    private _phaseSorter: PhaseSorter,
    private _progressService: NgProgressService,
    private _confirmationService: ConfirmationService,
  ) { }

  public createPhase(
    date: string,
    name: string,
    fixedStart: string,
    templateId?: number,
  ): Observable<any> {
    return this._graphqlService.createPhase(date, name, fixedStart, templateId)
      .pipe(
        tap({
          next: (result) => {
            const phase = result.data.createPhase.phase;
            const activity = result.data.createPhase.activity;

            if (phase?.fixedStart) {
              phase.fixedStart = DateTime.fromSQL(
                phase.date + ' ' + phase.fixedStart,
              );
            }
            phase.date = DateTime.fromSQL(phase.date);
            phase.id = +phase.id;
            phase.activities = [];

            const phases = this.getPhasesFromMap(date, templateId);
            phases.push(phase);
            this.setDayPhases(date, phases, templateId);

            activity.date = DateTime.fromSQL(activity.date);
            activity.phaseDefault = activity.phaseDefault as boolean;

            this._store.dispatch(addActivityInStoreAction({ activity }));
            this.updateChangedActivities(result.data.createPhase.changedActivities);
            this.sortDay$(date, templateId).subscribe(() => this.refreshDays());

            this._notificationService.success({
              description: this._translate.instant(marker('Successfully created phase')),
            });
          },
          error: () => {
            this._notificationService.error({
              description: this._translate.instant(marker('Failed to create phase')),
            });
          },
        }),
      );
  }

  public updatePhase(phase: Phase): Observable<any> {
    return this._graphqlService.updatePhase(phase.id, phase.fixedStart, phase.name, phase.orderActivities)
      .pipe(
        tap({
          next: () => {
            this.sortDay$(phase.date.toFormat('yyyy-MM-dd'), phase.templateId).subscribe(() => this.refreshDays());
          },
          error: () => {
            this._notificationService.error({
              description: this._translate.instant(marker('Failed to update phase')),
            });
          },
        }),
      );
  }

  public insertNewDayFromTemplate(templateId: number, date: string): Observable<any> {
    this._progressService.start();
    return this._graphqlService.insertNewDayFromTemplate(templateId, date)
      .pipe(
        finalize(() => this._progressService.complete()),
      );
  }

  public setDayPhases(date: string, phases: Phase[], templateId?: number): void {
    this.#daysMap.set(this.buildDaysKey(date, templateId), phases);
  }

  public sortDay$(date: string, templateId?: number): Observable<Activity[]> {
    if (!this.hasDayPhases(date, templateId)) {
      return of([]).pipe(take(1));
    }

    return this._store.select(selectAllActivities)
      .pipe(
        filter((activities) => !!activities?.length),
        take(1),
        tap((activities) => {
          const sortedPhases = this._phaseSorter.sort(
            [...activities],
            this.getPhasesFromMap(date, templateId),
          );
          this.setDayPhases(date, sortedPhases, templateId);
        }),
      );
  }

  public refreshDays(): void {
    this.onRefreshDays$.next();
  }

  public hasDayPhases(date: string, templateId?: number): boolean {
    return this.#daysMap.has(this.buildDaysKey(date, templateId));
  }

  /**
   * getting the phases from days map
   * @param date
   * @param templateId
   * @returns phases array
   */
  public getPhasesFromMap(date: string, templateId?: number): Phase[] {
    if (this.hasDayPhases(date, templateId)) {
      return this.#daysMap.get(this.buildDaysKey(date, templateId));
    }
    return [];
  }

  public patchPhasesByDay(date: string, templateId?: number): void {
    if (this.hasDayPhases(date, templateId)) {
      patchState(this.#state, {
        phases: this.getPhasesFromMap(date, templateId),
      });
    } else {
      patchState(this.#state, {
        phases: [],
      });
    }
  }

  public deleteDay(date: string): Observable<any> {
    return this._confirmationService.openDialog()
      .pipe(
        filter((confirmed) => !!confirmed),
        switchMap(() => {
          this._progressService.start();
          return this._graphqlService.deleteDay(date);
        }),
        tap(() => {
          const phases = this.getPhasesFromMap(date);
          phases?.forEach((phase) =>
              this._store.dispatch(deleteActivitiesByPredicateAction({
                predicate: (item: Activity) => phase.id === item.phase.id,
              })));

          this.deleteAllDayPhases(date);
          patchState(this.#state, {
            phases: this.getPhasesFromMap(date),
          });
          this._progressService.complete();
        }),
      );
  }

  public deletePhase(phase: Phase): void {
    const deletePhaseSub = this._confirmationService.openDialog(
      this._translate.instant(marker('Are you sure you want to delete the phase?')),
    ).pipe(filter((confirmed) => confirmed))
      .subscribe(() => {
        this._graphqlService.deletePhase(phase.id)
          .subscribe({
              next: (result: any) => {
                const removedActivitiesIds = result.data.deletePhase.removedActivities?.map((item) => +item.id);
                const phases = this.getPhasesFromMap(phase.date.toFormat('yyyy-MM-dd'), phase.templateId)?.filter((ph) => ph.id !== phase.id);

                this.updateChangedActivities(result.data.deletePhase.changedActivities);
                this._deletePhaseFromDay(phase.date.toFormat('yyyy-MM-dd'), phases, phase.templateId);
                this._store.dispatch(deleteActivitiesByIdsAction({ ids: removedActivitiesIds }));
                this.refreshDays();

                this._notificationService.success({
                  description: this._translate.instant(marker('Successfully deleted phase')),
                });
                deletePhaseSub.unsubscribe();
            },
            error: (error) => {
              this._notificationService.error({
                description: this._translate.instant(marker('Failed to delete phase')),
              });
            },
          });
      });
  }

  public addNewActivityToPhase(phase: Phase, description: string, duration: number, fixedStart: DateTime): Observable<any> {
    return this._graphqlService.newActivityToPhase(phase.date, description, duration, phase.id, fixedStart)
      .pipe(
        tap({
          next: (result) => {
            const act = result.data.newActivityToPhase.activity;
            act.date = DateTime.fromSQL(act.date);
            act.id = +act.id;

            if (act.fixedStart) {
              act.fixedStart = DateTime.fromSQL(act.fixedStart);
            }

            this._store.dispatch(addActivityInStoreAction({ activity: act }));
            this.updateChangedActivities(result.data.newActivityToPhase.changedActivities);

            this.sortDay$(act.date.toFormat('yyyy-MM-dd'), phase.templateId)
              .subscribe(() => this.refreshDays());
          },
          error: () => {
            this._notificationService.error({
              description: this._translate.instant(marker('Failed to add activity')),
            });
          },
        }),
      );
  }

  public updateChangedActivities(changedActivities: ChangedActivitiesResp[]): void {
    if (!changedActivities?.length) {
      return;
    }

    const activities: Update<Activity>[]  = changedActivities?.map((item: ChangedActivitiesResp) => ({
      id: item.i,
      changes: {
        ...(item.n !== -1 ? { nextActivityId: +item.n } : {} ),
        ...(item.p !== -1 ? { previousActivityId: +item.p } : {} ),
        ...(item.ph !== -1 ? { phase: this._getPhase(item.ph) } : {} ),
        ...(!!item.date ? { date: DateTime.fromSQL(item.date) } : {} ),
      },
    }));
    this._store.dispatch(updateManyActivitiesInStoreAction({ updates: activities }));
  }

  public deleteAllDayPhases(date: string, templateId?: number): void {
    if (this.hasDayPhases(date, templateId)) {
      this.#daysMap.delete(this.buildDaysKey(date, templateId));
    }
  }

  private _getPhase(phaseId: number): Phase | undefined {
    for (const value of this.#daysMap.values()) {
      for (const phase of value) {
        if (phase.id === phaseId) {
          return phase;
        }
      }
    }
    return undefined;
  }

  private _deletePhaseFromDay(date: string, values: Phase[], templateId?: number ) {
    this.#daysMap.set(this.buildDaysKey(date, templateId), values);
  }

  /**
   * Helper function to build the key for days map
   * @param date
   * @param templateId
   * @returns the map key
   */
  private buildDaysKey(date: string, templateId?: number): string {
    templateId === undefined ? templateId = -1 : templateId;
    return (date + templateId) as string;
  }

}
