import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetEwsConfigGQL extends Query<any> {
  document = gql`
    query  ews_config {
      ews_config {
        id
        ews_password
        ews_url
        ews_user
        ews_exchange_version
      }
    }
  `;
}
