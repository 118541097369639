import { Component, Input } from '@angular/core';
import { Phase } from '../../../../../core/models/interfaces';
import { convertHoursToMinutes, convertMinutesToHours } from '../../../../../_helpers/helpers';
import { DateTime } from 'luxon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'time-progress',
    templateUrl: './time-progress.component.html',
    styleUrls: ['./time-progress.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class TimeProgressComponent {
  @Input() phase: Phase = null;
  @Input() phases: Phase[] = [];
  @Input() date: string = '';
  @Input() nextPhase: Phase;

  constructor() { }

  public convertMinutesToHours(time: number): string {
    return convertMinutesToHours(time);
  }

  get phaseProgressTime(): number {
    return +this.phase.activities.reduce((a, b) => {
      return a + +b.duration;
    }, 0);
  }

  get allPhaseTime(): number {
    const currentPhaseStartTimeDuration = convertHoursToMinutes(this.phase.fixedStart);
    const lastHourInDayDate = DateTime.fromISO(this.date).set({hour: 23, minute: 59, second: 59, millisecond: 59});
    const dateTime = this.nextPhase ? this.nextPhase.fixedStart : lastHourInDayDate;
    return convertHoursToMinutes(dateTime, !this.nextPhase) - currentPhaseStartTimeDuration;
  }

  get progressPercent(): number {
    return +(this.phaseProgressTime * 100 / this.allPhaseTime).toFixed();
  }

  get timeOutBounds(): boolean {
    return this.progressPercent > 100;
  }

  get getProgressPercent(): number {
    const percent = this.progressPercent;
    return percent <= 100 ? percent : 100;
  }
}
