import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import {
  ActivityStatus,
  ChangeTaskStatusActivity,
  PlanningBoardTask,
  PriorityID,
  sortStep,
  UUID,
} from '../helpers/planning-board.helper';
import { NotificationService } from '../../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from '../../../../../core/models/interfaces';
import { GenerateDefaultActivityForTaskGQL } from '../graphql/generateDefaultActivityForTaskGQL';
import { catchError, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { FetchAllGroupContainersGQL } from '../graphql/fetchAllClipboardActivitiesGQL';
import { TasksService } from './tasks.service';
import { NgProgressService } from '../../../../../shared/services/ng-progress.service';
import { deepClone } from '../../../../../_helpers/helpers';
import { BigInt } from './group-containers.service';
import { DeleteActivityByPkGQL } from '../../../../../core/services/graphql-queries/deleteActivityByPkGQL';
import { ActivitiesService } from './activities.service';
import { MutationResult } from 'apollo-angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.state';
import { selectAllActivities } from '../../../../../store/clipboard-activities/clipboard-activities.selectors';
import { addActivityToClipboardStoreAction } from '../../../../../store/clipboard-activities/clipboard-activities.actions';

export const CLIPBOARD_VISIBILITY_STORAGE_KEY = 'clipboard-visible';

@Injectable({ providedIn: 'root' })
export class PlanningBoardClipboardService {
  private _clipboardVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!localStorage.getItem(CLIPBOARD_VISIBILITY_STORAGE_KEY));
  public newActivityForTask$: BehaviorSubject<Activity> = new BehaviorSubject<Activity>(null);
  private _clipboardActivities$: BehaviorSubject<Activity[]> = new BehaviorSubject<Activity[]>([]);

  constructor(
    private store: Store<AppState>,
    private deleteActivityByPkGQL: DeleteActivityByPkGQL,
    private tasksService: TasksService,
    private progressService: NgProgressService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activitiesService: ActivitiesService,
    private fetchAllGroupContainersGQL: FetchAllGroupContainersGQL,
    private generateDefaultActivityForTaskGQL: GenerateDefaultActivityForTaskGQL,
  ) { }

  get clipboardVisible$(): BehaviorSubject<boolean> {
    return this._clipboardVisible$;
  }

  get orderInClipboard(): number {
    let clipboardActivities = [];
    this.store.select(selectAllActivities)
      .pipe(take(1))
      .subscribe((acts) => clipboardActivities = acts);

    return !!clipboardActivities.length
      ? clipboardActivities[clipboardActivities.length - 1].order_in_clipboard + sortStep
      : sortStep;
  }

  public toggleVisibility(visibility: boolean): void {
    if (!!visibility) {
      localStorage.setItem(CLIPBOARD_VISIBILITY_STORAGE_KEY, 'true');
    } else {
      localStorage.removeItem(CLIPBOARD_VISIBILITY_STORAGE_KEY);
    }
    this._clipboardVisible$.next(visibility);
  }

  public addClipboardActivity(activity: Activity): Activity {
    activity.in_clipboard = true;
    activity.order_in_clipboard = this.orderInClipboard;

    return activity;
  }

  public addClipboardTask(task: PlanningBoardTask, activityStatus: ActivityStatus, notificationMsg: string): Observable<any> {
    task.clipboard = true;
    const activityToClipboard: Activity = this.prepareActivityFromTask(task, activityStatus);
    this.progressService.start();
    return combineLatest([this.tasksService.updateTask$(task), this.generateActivityInClipboard(
      task.id,
      activityToClipboard.description,
      activityToClipboard.duration,
      activityToClipboard.priority.id,
      activityToClipboard.order_in_clipboard,
    )]).pipe(
      tap(([task, activity]) => {
        activityToClipboard.id = activity.id;
      }),
      finalize(() => {
        this._clipboardActivities$.next([...this._clipboardActivities$.getValue(), activityToClipboard]);
        this.notificationService.success({
          description: this.translate.instant(notificationMsg),
        });
        this.progressService.complete();
      }),
      mergeMap(() => of(activityToClipboard)),
    );
  }

  public cloneClipboardActivity(activity: Activity): Observable<ChangeTaskStatusActivity> {
    const clonedActivity: Activity = deepClone(activity);
    if (!!activity.done) {
      clonedActivity.done = false;
    }
    this.progressService.start();
    let clipboardActivities = [];
    this.store.select(selectAllActivities).pipe(take(1)).subscribe((activities) => clipboardActivities = activities);
    const orderInClipboard = clipboardActivities[clipboardActivities.length - 1].order_in_clipboard + sortStep;

    return this.generateActivityInClipboard(
      clonedActivity.task?.id,
      clonedActivity.description,
      clonedActivity.duration,
      clonedActivity.priority?.id,
      orderInClipboard,
    ).pipe(
      finalize(() => this.progressService.complete()),
      tap((res) => {
        if (!res || !res.id) {
          return;
        }
        clonedActivity.id = res.id;
        this.store.dispatch(addActivityToClipboardStoreAction({ activity: clonedActivity }));
        this.newActivityForTask$.next(clonedActivity);
      }),
      catchError((error) => throwError(() => new Error(error))),
    );
  }

  public deleteActivity(activity: Activity): Observable<MutationResult<any>> {
    if (!!activity.phase) {
      return this.activitiesService.assignTaskToActivity(activity.id, null)
        .pipe(finalize(() => {
          this.removeActivityFromFrontend(activity.id);
        }));
    }

    return this.deleteActivityByPkGQL.mutate({id: activity.id})
      .pipe(finalize(() => {
        this.removeActivityFromFrontend(activity.id);
      }));
  }

  public removeActivityFromFrontend(id: BigInt): void {
    this._clipboardActivities$.next(this._clipboardActivities$.getValue().filter((el) => +el.id !== +id));
  }

  private generateActivityInClipboard(
    task_id: UUID,
    description: string,
    duration: number,
    priority_id: PriorityID,
    order_in_clipboard: number,
  ): Observable<ChangeTaskStatusActivity> {
    return this.generateDefaultActivityForTaskGQL.mutate({
      task_id,
      description,
      duration,
      priority_id,
      order_in_clipboard,
    }).pipe(map(
      (res) => res['data']['insert_activities_one'],
    ));
  }

  prepareActivityFromTask(task: PlanningBoardTask, activityStatus: ActivityStatus): Activity {
    return {
      id: new Date().getTime(),
      date: null,
      description: task.description,
      duration: task.duration,
      note: null,
      status: activityStatus.key,
      done: false,
      in_clipboard: true,
      order_in_clipboard: this.orderInClipboard,
      order_in_phase: null,
      order_in_task: null,
      task: {
        id: task.id,
        is_implicit: task.is_implicit,
        description: task.description,
        group: task.group,
      },
      priority: task.priority,
      appointment: null,
      templateId: null,
    };
  }
}
