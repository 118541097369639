import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Appointment } from '../../../core/models/interfaces';
import { AppState } from '../../../store/app.state';
import { selectAllActivities } from '../../../store/activities/activities.selectors';

@Injectable({
  providedIn: 'root',
})
export class CaldavService {

  constructor(
    private _store: Store<AppState>,
    private http: HttpClient,
  ) { }

  public getCaldavEvents(date: String, timezone: String): Observable<any> {
    let myparams = new HttpParams();

    myparams = myparams.append('date', encodeURIComponent(date.toString()));
    myparams = myparams.append('tz', encodeURIComponent(timezone.toString()));

    console.log(myparams.toString());

    return this.http.get('/caldav', {params: myparams}).pipe(map((result: any) => {
      let activities = [];
      this._store.select(selectAllActivities).pipe(take(1)).subscribe((acts) => activities = acts);

      let items = [];

      if (result.length) {
        result.forEach((item) => {
          try {
            const appointment: Appointment = {
              extId: item.extId,
              extLink: item.extLink,
              summary: item.summary,
              startTime: DateTime.fromISO(item.startTime),
              endTime: DateTime.fromISO(item.endTime),
              allDay: item.allDay,
              provider: 'caldav',
              planned: activities.some((act) => act.appointment ? act.appointment.extId === item.extId : false),
            };
            appointment.startTime = this.setDate(appointment.startTime, date);
            appointment.endTime = this.setDate(appointment.endTime, date);
            items.push(appointment);
          } catch (message) {
            console.error(message);
          }
        });
      }
      return items;
    }),
    );
  }

  /**
   * Sets date on an existing DateTime object
   * @param dateTime The object to change date for
   * @param date The date to set
   */
  private setDate(dateTime: DateTime, date: String): DateTime {
    let dateObj = DateTime.fromISO(date.toString());
    dateTime = dateTime.set({day: dateObj.day, month: dateObj.month, year: dateObj.year});
    return dateTime;
  }
}
