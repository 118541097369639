import { RouterModule, Routes } from '@angular/router';
import { DayEditorComponent } from './modules/day-planner/components/day-editor/day-editor.component';
import { TemplateEditorComponent } from './modules/day-planner/components/template-editor/template-editor.component';
import { RootComponent } from './core/components/root/root.component';
import { PrivacyPolicyComponent } from './shared/info-pages/privacy-policy/privacy-policy.component';
import { KeycloakAuthGuard } from './core/guards/keycloak/keycloak.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [KeycloakAuthGuard],
    loadChildren: () => import('./modules/auth/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: '',
    canActivate: [KeycloakAuthGuard],
    component: RootComponent,
    children: [
      {
        path: '',
        redirectTo: 'day-editor',
        pathMatch: 'full',
      },
      {
        path: 'day-editor', component: DayEditorComponent,
      },
      {
        path: 'template-editor', component: TemplateEditorComponent,
      },
      {
        path: 'planning-board',
        loadComponent: () => import('./modules/day-planner/components/planning-board/planning-board.component').then((m) => m.PlanningBoardComponent),
      },
      {
        path: 'privacy-policy', component: PrivacyPolicyComponent,
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/day-planner/components/settings/settings.routes').then((m) => m.SETTINGS_ROUTES),
      },
      {
        path: '**',
        redirectTo: 'day-editor',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const appRoutes = RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledNonBlocking',
    scrollOffset: [0, 0],
    useHash: false,
    onSameUrlNavigation: 'reload',
});
