import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateGlobalSettingGQL extends Mutation {
  document = gql`
    mutation update_global_settings($value: String = "", $key: String = "") {
      update_global_settings(where: {key: {_eq: $key}}, _set: {value: $value}) {
        affected_rows
      }
    }`;
}
