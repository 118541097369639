import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Activity } from '../../core/models/interfaces';
import * as fromActivitiesReducer from './clipboard-activities.reducer';

export const selectFeature = createFeatureSelector<AppState>('clipboardActivities');

export const selectAllActivities = createSelector<AppState, any, Activity[]>(
  selectFeature,
  fromActivitiesReducer.selectAllActivities,
);

export const selectActivitiesEntities = createSelector<AppState, any, any>(
  selectFeature,
  fromActivitiesReducer.selectActivitiesEntities,
);

export const selectActivity = (activityrId: number) => createSelector(
  selectActivitiesEntities,
  (activityrEntities) => activityrEntities[activityrId],
);
