<div class="c-dayEditor__header">
    <div class="c-dayEditorCalendar">
        <!-- CURRENT DATE -->
        <div class="c-dayEditorCalendar__month">
            <div class="c-dayEditorCalendar__monthTitle">{{navigationWeekMonth | date:'LLLL, yyyy' }}</div>
            <div [id]="DayEditorOnboardingIdEnum.Fourth">
                <input [matDatepicker]="picker" (dateChange)="setDate($event.value)" [formControl]="selectedDateControl"
                    class="c-dayEditorCalendar__monthInput">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
        </div>

        <!-- WEEK RANGE -->
        <div class="c-dayEditorCalendar__week">
            <div class="c-dayEditorCalendar__day"
                [id]="isSelectedDay(day.date) ? DayEditorOnboardingIdEnum.Second : null" *ngFor="let day of week"
                [ngClass]="{
                    'active': isSelectedDay(day.date),
                    'today': isToday(day.date)
                    }" (click)="setDate(day.date)">
                <div class="c-dayEditorCalendar__dayName">
                    {{day.name | translate}}
                </div>
                <div class="c-dayEditorCalendar__dayNumber">
                    {{day.date.getDate()}}
                </div>
            </div>
        </div>

        <!-- CALENDAR ACTIONS -->
        <div class="c-dayEditorCalendar__nav" [id]="DayEditorOnboardingIdEnum.Third">
            <button class="c-dayEditorCalendar__navBtn" (click)="prevWeek()" matRipple>
                <mat-icon svgIcon="chevron-left"></mat-icon>
            </button>
            <button class="c-dayEditorCalendar__navBtn" (click)="setDate(today)"
                [matTooltip]="'Jump to today' | translate" matRipple>&#8226;
            </button>
            <button class="c-dayEditorCalendar__navBtn" (click)="nextWeek()" matRipple>
                <mat-icon svgIcon="navigate-next"></mat-icon>
            </button>
        </div>
    </div>
</div>