import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { PreloaderComponent } from './preloader.component';

let TARGETS_ARRAY = [];
function updateCurrentLoadersInUse(target: Object, method: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = function(...args: any[]) {
    if (method === 'show') {
      args[0].pmdPreloaderId ? null : args[0].pmdPreloaderId = uuidv4();
      TARGETS_ARRAY.includes(args[0].pmdPreloaderId) ? null : TARGETS_ARRAY.push(args[0].pmdPreloaderId);
    }
    if (method === 'close') {
      TARGETS_ARRAY = TARGETS_ARRAY.filter((e) => e !== args[0].pmdPreloaderId);
    }
    return originalMethod.apply(this, args);
  }
}

/**
 * Preloader service
 */
@Injectable()
export class PreloaderService {
  private preloaderPortal: ComponentPortal<PreloaderComponent> = new ComponentPortal(PreloaderComponent);
  private bodyPortalHost: DomPortalOutlet;

  private _opened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get opened(): boolean {
    return this._opened.getValue();
  }

  get openedChanges(): Observable<boolean> {
    return this._opened.asObservable();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  public showInContent(elementRef: Element): void {
    if (!this.opened) {
      this.generatePortalHost(elementRef);
      this.bodyPortalHost?.attachComponentPortal(this.preloaderPortal);
      this._opened.next(true);
    }
  }

  /**
   * param "instance" needed for decorator and includes "this"
   * @param instance
   */
  @updateCurrentLoadersInUse
  public show<T>(instance: T): void {
    this.generatePortalHost();
    if (!this.opened) {
      this.bodyPortalHost?.attachComponentPortal(this.preloaderPortal);
      this._opened.next(true);
    }
  }

  /**
   * param "instance" needed for decorator and includes "this"
   * @param instance
   */
  @updateCurrentLoadersInUse
  public close<T>(instance: T): void {
    if (this.opened && !TARGETS_ARRAY.length) {
      this.bodyPortalHost.detach();
      this._opened.next(false);
      this._deleteFromDOMIfDetachNotWorks();
    }
  }

  private _deleteFromDOMIfDetachNotWorks(): void {
    const el = this.document.querySelector('pmd-preloader');
    if (el) {
      el.remove();
    }
  }

  private generatePortalHost(elementRef: Element | null = null): void {
    this.bodyPortalHost = new DomPortalOutlet(
      elementRef ?? document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector,
    );
  }
}
