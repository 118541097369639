import { ChangeDetectorRef, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Priority, Template } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { TEMPLATE_DATE } from '../../../../_helpers/constants.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, startWith, take } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatDivider } from '@angular/material/divider';
import { MatList, MatListItem } from '@angular/material/list';
import { MatOption, MatRipple } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { MatIcon } from '@angular/material/icon';
import { PhaseListComponent } from '../phase-list/phase-list.component';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { AddPhaseDialog } from '../day-editor/components/add-phase-dialog/add-phase-dialog.component';
import { EditTemplateDialog } from './components/edit-template-dialog/edit-template-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { selectAllPriorities } from '../../../../store/priorities/priorities.selectors';
import { selectAllPhaseTemplates } from '../../../../store/phase-templates/phase-templates.selectors';
import {
  cloneTemplateAction,
  createTemplateAction,
  deleteTemplateAction,
  updateTemplateAction,
} from '../../../../store/phase-templates/phase-templates.actions';
import { ConfirmationService } from '../../../../core/services/confirmation.service';
import { PhasesService } from '../../../../core/services/phases.service';

@UntilDestroy()
@Component({
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss'],
    standalone: true,
    imports: [
        CdkDropListGroup,
        PhaseListComponent,
        MatIcon,
        AppButtonComponent,
        NgIf,
        MatFormField,
        MatLabel,
        MatSelect,
        NgFor,
        MatOption,
        MatList,
        MatListItem,
        MatRipple,
        MatDivider,
        TranslateModule,
    ],
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
  // Signals
  public phases = this._phasesService.allPhaseByDaySignal;
  public templates = signal<Template[]>([]);
  public priorities = signal<Priority[]>([]);

  // Vars
  public date: string = TEMPLATE_DATE;
  public selectedTemplate: Template;
  public deleteOn: boolean = false;

  constructor(
    public addPhaseDialog: MatDialog,
    public editTemplateDialog: MatDialog,
    private _store: Store<AppState>,
    private _dataProviderService: DataproviderService,
    private _phasesService: PhasesService,
    private _confirmationService: ConfirmationService,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this._phasesService.onRefreshDays$
    .pipe(startWith(undefined), untilDestroyed(this))
    .subscribe(() => {
      this._refreshPhases();
    });

    this._store.select(selectAllPhaseTemplates)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (templates) => {
          this.templates.set(templates);
        },
      });

    this._store.select(selectAllPriorities)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (priorities) => {
          this.priorities.set(priorities);
        },
      });
  }

  private _refreshPhases(): void {
    if (this.selectedTemplate !== undefined) {
      this._phasesService.patchPhasesByDay(this.date, this.selectedTemplate.id);
      this._cd.detectChanges();
    }
  }

  public editTemplate(): void {
    // opens a modal dialog to edit the template attributes
    if (this.selectedTemplate !== undefined) {
      const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
        data: {template: this.selectedTemplate},
      });

      dialogRef.afterClosed()
        .pipe(
          take(1),
          filter((template: Template) => !!template),
          untilDestroyed(this),
        ).subscribe((template: Template) => {
          this._store.dispatch(updateTemplateAction({
            templateId: template.id,
            name: template.name,
            active: template.active,
            msg: marker('Successfully update template'),
          }));
        });
    }
  }

  public createTemplate(): void {
    const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
      data: {template: undefined},
    });

    dialogRef.afterClosed().pipe(
      take(1),
      filter((template) => !!template),
      untilDestroyed(this),
    ).subscribe((result) => {
      this._store.dispatch(createTemplateAction({
        name: result.name,
        active: result.active,
        msg: marker('Successfully created template'),
      }));
    });
  }

  public cloneTemplate(): void {
    if (!!this.selectedTemplate) {
      const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
        data: {template: undefined},
      });

      dialogRef.afterClosed().pipe(
        take(1),
        filter((template) => !!template),
        untilDestroyed(this),
      ).subscribe((result) => {
        this._store.dispatch(cloneTemplateAction({
          selectedTemplateId: this.selectedTemplate.id,
          name: result.name,
          msg: marker('Successfully cloned template'),
        }));
      });
    }
  }

  public deleteTemplate(): void {
    this._confirmationService.openDialog()
      .pipe(
        take(1),
        filter((confirmed) => !!confirmed),
        untilDestroyed(this),
      ).subscribe(() => {
        this._store.dispatch(deleteTemplateAction({
          date: this.date,
          selectedTemplateId: this.selectedTemplate.id,
          msg: marker('Successfully deleted template'),
        }));

        this._phasesService.deleteAllDayPhases(this.date, this.selectedTemplate.id);
        this._phasesService.refreshDays();

        this.selectedTemplate = undefined;
      });
  }

  public openPhaseAddDialog(): void {
    if (!!this.selectedTemplate) {
      this.addPhaseDialog.open(AddPhaseDialog, {
        data: { date: this.date, templateId: this.selectedTemplate.id },
      });
    }
  }

  public changeTemplate(): void {
    this._dataProviderService.fetchAllActivitiesAndPhases(this.date, this.selectedTemplate.id);
    this.deleteOn = !this.selectedTemplate.default;
  }

  ngOnDestroy(): void {
    this._phasesService.patchPhasesByDay(null);
  }

}
