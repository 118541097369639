import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivitiesService } from './activities.service';
import { CancelActivitiesForTaskGQL } from '../graphql/cancelActivitiesForTaskGQL';
import { CreateNewTaskGQL } from '../graphql/createNewTaskGQL';
import { MoveTaskInsideContainerGQL } from '../graphql/moveTaskInsideContainerGQL';
import { MoveTaskToAnotherContainerGQL } from '../graphql/moveTaskToAnotherContainerGQL';
import { UpdateTaskByPkGQL } from '../graphql/updateTaskByPkGQL';
import {
  GroupId,
  ChangeTaskStatusActivity,
  PlanningBoardTask,
  PriorityID,
  TaskStatus, TaskStatusKey,
  UUID,
} from '../helpers/planning-board.helper';
import { ToggleTaskExpandingGQL } from '../graphql/toggleTaskExpandingGQL';
import { DeleteTaskGQL } from '../graphql/deleteTaskGQL';
import { UpdateTaskStatusGQL } from '../graphql/updateTaskStatusGQL';
import { NumberOfOpenedActivitiesForTaskGQL } from '../graphql/numberOfOpenedActivitiesForTaskGQL';
import { FetchTasksStatusesGQL } from '../graphql/fetchTasksStatusesGQL';
import { RemoteSchemaCascadeMutationOptions } from '../helpers/remote-schema.types';
import { SetExplicitTaskTypeGQL } from '../graphql/setExplicitTaskTypeGQL';
import { FinishImplicitTaskGQL } from '../graphql/finishImplicitTaskGQL';
import { FinishExplicitTaskGQL } from '../graphql/finishExplicitTaskGQL';

import ShouldBe = RemoteSchemaCascadeMutationOptions

export type ChangeTaskStatusResponse = {
  errors?: string[],
  task?: PlanningBoardTask,
  changedActivities?: ChangeTaskStatusActivity[]
}

@Injectable({providedIn: 'root'})
export class TasksService {

  constructor(
    private activitiesService: ActivitiesService,
    private createNewTaskGQL: CreateNewTaskGQL,
    private moveTaskToAnotherContainerGQL: MoveTaskToAnotherContainerGQL,
    private moveTaskInsideContainerGQL: MoveTaskInsideContainerGQL,
    private updateTaskByPkGQL: UpdateTaskByPkGQL,
    private toggleTaskExpandingGQL: ToggleTaskExpandingGQL,
    private deleteTaskGQL: DeleteTaskGQL,
    private cancelActivitiesForTaskGQL: CancelActivitiesForTaskGQL,
    private updateTaskStatusGQL: UpdateTaskStatusGQL,
    private numberOfOpenedActivitiesForTaskGQL: NumberOfOpenedActivitiesForTaskGQL,
    private fetchTasksStatusesGQL: FetchTasksStatusesGQL,
    private finishImplicitTaskGQL: FinishImplicitTaskGQL,
    private finishExplicitTaskGQL: FinishExplicitTaskGQL,
    private setExplicitTaskTypeGQL: SetExplicitTaskTypeGQL,
  ) {
  }

  createNewTask$(
    group_id: GroupId,
    description: string,
    duration: number,
    priority_id: PriorityID,
    order_in_group: number,
    status: string,
    is_implicit: boolean,
    is_expanded: boolean,
  ): Observable<any> {
    return this.createNewTaskGQL.mutate({group_id, description, duration, priority_id, order_in_group, status, is_implicit, is_expanded});
  }

  moveTask$(
    id: UUID,
    order_in_group: number,
    group_id: GroupId | null,
  ): Observable<any> {
    if (group_id) {
      return this.moveTaskToAnotherContainerGQL.mutate({id, order_in_group, group_id});
    } else {
      return this.moveTaskInsideContainerGQL.mutate({id, order_in_group});
    }
  }

  updateTask$(updatedTask: PlanningBoardTask): Observable<PlanningBoardTask> {
    return this.updateTaskByPkGQL.mutate({
      id: updatedTask.id,
      description: updatedTask.description,
      duration: updatedTask.duration,
      status: updatedTask.status,
      priority_id: updatedTask.priority.id,
      style: updatedTask.style,
      clipboard: updatedTask.clipboard,
    }).pipe(map(
      (res) => res['data']['update_tasks_by_pk'],
    ));
  }

  updateTaskExpanding$(taskId: string, expanded: boolean): Observable<any> {
    return this.toggleTaskExpandingGQL.mutate({id: taskId, is_expanded: expanded});
  }

  deleteTask$(
    id: UUID,
  ): Observable<PlanningBoardTask> {
    return this.deleteTaskGQL.mutate({id}).pipe(map(
      (res) => res['data']['update_tasks_by_pk'],
    ));
  }

  cancelActivitiesForTask$(
    task_id: UUID,
  ): Observable<{
    affected_rows: number,
    returning: ChangeTaskStatusActivity[],
  }> {
    return this.cancelActivitiesForTaskGQL.mutate({task_id}).pipe(map(
      (res) => res['data']['update_activities'],
    ));
  }

  // STATUSES
  setExplicitTaskType(id: string, isImplicit: boolean) {
    return this.setExplicitTaskTypeGQL.mutate({id, is_implicit: isImplicit, is_expanded: !isImplicit});
  }

  fetchStatuses$(): Observable<TaskStatus[]> {
    return this.fetchTasksStatusesGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges.pipe(
      map((res) => res['data']['statuses']));
  }

  finishExplicitTask$(
    id: UUID,
    unfinished_unplanned_activities: ShouldBe.Finished | ShouldBe.Deleted,
    unfinished_planned_activities: ShouldBe.Finished | ShouldBe.LeftWithoutTask,
  ): Observable<ChangeTaskStatusResponse> {
    return this.finishExplicitTaskGQL.mutate({
      id,
      unfinished_unplanned_activities,
      unfinished_planned_activities,
    }).pipe(map(
      (res) => res['data']['finishExplicitTask'],
    ));
  }

  finishImplicitTask$(
    id: UUID,
    unfinished_activities: ShouldBe.Finished | ShouldBe.LeftWithoutTask,
  ): Observable<ChangeTaskStatusResponse> {
    return this.finishImplicitTaskGQL.mutate({id, unfinished_activities}).pipe(map(
      (res) => res['data']['finishImplicitTask'],
    ));
  }

  updateStatus$(id: UUID, status: TaskStatusKey): Observable<PlanningBoardTask> {
    return this.updateTaskStatusGQL.mutate({id, status}).pipe(map(
      (res) => res['data']['update_tasks_by_pk'],
    ));
  }

  numberOfOpenedActivities$(id: UUID): Observable<number> {
    return this.numberOfOpenedActivitiesForTaskGQL.watch(
      {id}, {fetchPolicy: 'no-cache'},
    ).valueChanges.pipe((res) => {
      return res['data']['activities_aggregate']['aggregate']['count'];
    });
  }

  hasOpenedActivities$(id: UUID): Observable<boolean> {
    return this.numberOfOpenedActivities$(id).pipe(map(
      (res) => res && res > 0,
    ));
  }
}
