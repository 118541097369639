import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetGlobalSettingsGQL extends Query<any> {
  document = gql`
    query global_settings {
      global_settings {
        key
        value
        value_type
      }
    }
  `;
}
