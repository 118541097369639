import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class InsertGlobalSettingGQL extends Mutation {
  document = gql`
    mutation insert_global_settings_one($value: String = "", $key: String = "") {
      insert_global_settings_one(object: {key: $key, value: $value}) {
        key
        value
      }
    }`;
}
