import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class GetCalendarSelectionConfigGQL extends Query<any> {
  document = gql`
    query calendar_selection_config {
      calendar_selection_config {
        calendar_id
        enabled
      }
    }
  `;
}
