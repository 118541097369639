import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class CancelActivitiesForTaskGQL extends Mutation {
  document = gql`
  mutation CancelActivitiesForTaskGQL(
    $task_id: uuid,
  ) {
    update_activities(
      _set: {backlogged: true},
      where: {_and: {
          task_id: {_eq: $task_id},
          done: {_eq: false}
      }}
    ) {
      affected_rows
      returning {
        id
      }
    }
  }`;
}
