import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import {
  Activity,
  Appointment,
  EwsConfig,
  Priority,
} from '../models/interfaces';
import { ActivitiesPerDateGQL } from './graphql-queries/activitiesPerDateGQL';
import { ActivityByPkGQL } from './graphql-queries/activityByPkGQL';
import { CheckEwsConfigGQL } from './graphql-queries/checkEwsConfigGQL';
import { CloneActivityGQL } from './graphql-queries/cloneActivityGQL';
import { CloneTemplateGQL } from './graphql-queries/cloneTemplateGQL';
import { CreateAppointmentGQL } from './graphql-queries/createAppointmentGQL';
import { CreatePhaseGQL } from './graphql-queries/createPhaseGQL';
import { CreateTemplateGQL } from './graphql-queries/createTemplateGQL';
import { DeleteDayGQL } from './graphql-queries/deleteDayGQL';
import { DeletePhaseGQL } from './graphql-queries/deletePhaseGQL';
import { DeleteTemplateGQL } from './graphql-queries/deleteTemplateGQL';
import { EwsAppointmentsGQL } from './graphql-queries/ewsAppointmentsGQL';
import { GetEwsConfigGQL } from './graphql-queries/getEwsConfigGQL';
import { GetEwsExchangeVersionsGQL } from './graphql-queries/GetEwsExchangeVersionsGQL';
import { GetGlobalSettingsGQL } from './graphql-queries/getGlobalSettingsGQL';
import { GetPrioritiesGQL } from './graphql-queries/getPrioritiesGQL';
import { GetTemplatesGQL } from './graphql-queries/getTemplates';
import { InsertEwsConfigGQL } from './graphql-queries/insertEwsConfigGQL';
import { InsertGlobalSettingGQL } from './graphql-queries/insertGlobalSettingGQL';
import { InsertNewDayFromTemplateGQL } from './graphql-queries/insertNewDayFromTemplateGQL';
import { MoveActivityByIdGQL } from './graphql-queries/moveActivityByIdGQL';
import { NewActivityToPhaseGQL } from './graphql-queries/newActivityToPhaseGQL';
import { UpdateActivityGQL } from './graphql-queries/updateActivityGQL';
import { UpdateEwsConfigGQL } from './graphql-queries/updateEwsConfigGQL';
import { UpdateGlobalSettingGQL } from './graphql-queries/updateGlobalSettingGQL';
import { UpdatePhaseGQL } from './graphql-queries/updatePhaseGQL';
import { UpdateTemplateGQL } from './graphql-queries/updateTemplateGQL';
import { UpsertGlobalSettingGQL } from './graphql-queries/upsertGlobalSettingGQL';
import { map } from 'rxjs/operators';
import { GetCalendarSelectionConfigGQL } from './graphql-queries/getCalendarSelectionConfig';
import { InsertCalendarToSelectionConfigGQL } from './graphql-queries/insertCalendarToSelectionConfigGQL';
import { UpdateCalendarConfigGQL } from './graphql-queries/updateCalendarConfigGQL';
import { UpdateActivityPriorityGQL } from './graphql-queries/updateActivityPriorityGQL';
import { DeleteActivityFromListGQL } from './graphql-queries/deleteActivityFromListGQL';
import { RemoveActivityFromListGQL } from './graphql-queries/removeActivityFromListGQL';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(
    private apollo: Apollo,
    private activityByPkGQL: ActivityByPkGQL,
    private activitiesPerDateGQL: ActivitiesPerDateGQL,
    private newActivityToPhaseGQL: NewActivityToPhaseGQL,
    private deleteActivityFromListGQL: DeleteActivityFromListGQL,
    private removeActivityFromListGQL: RemoveActivityFromListGQL,
    private moveActivityByIdGQL: MoveActivityByIdGQL,
    private updateActivityGQL: UpdateActivityGQL,
    private insertNewDayFromTemplateGQL: InsertNewDayFromTemplateGQL,
    private createTemplateGQL: CreateTemplateGQL,
    private updateTemplateGQL: UpdateTemplateGQL,
    private deleteTemplateGQL: DeleteTemplateGQL,
    private cloneTemplateGQL: CloneTemplateGQL,
    private deleteDayGQL: DeleteDayGQL,
    private createPhaseGQL: CreatePhaseGQL,
    private updatePhaseGQL: UpdatePhaseGQL,
    private deletePhaseGQL: DeletePhaseGQL,
    private cloneActivityGQL: CloneActivityGQL,
    private createAppointmentGQL: CreateAppointmentGQL,
    private ewsAppointmentsGQL: EwsAppointmentsGQL,
    private getCalendarSelectionConfigGQL: GetCalendarSelectionConfigGQL,
    private insertCalendarToSelectionConfigGQL: InsertCalendarToSelectionConfigGQL,
    private updateCalendarConfigGQL: UpdateCalendarConfigGQL,
    private getGlobalSettingsGQL: GetGlobalSettingsGQL,
    private updateGlobalSettingGQL: UpdateGlobalSettingGQL,
    private insertGlobalSettingGQL: InsertGlobalSettingGQL,
    private upsertGlobalSettingGQL: UpsertGlobalSettingGQL,
    private getPrioritiesGQL: GetPrioritiesGQL,
    private getEwsConfigGQL: GetEwsConfigGQL,
    private getEwsExchangeVersionsGQL: GetEwsExchangeVersionsGQL,
    private updateEwsConfigGQL: UpdateEwsConfigGQL,
    private insertEwsConfigGQL: InsertEwsConfigGQL,
    private checkEwsConfigGQL: CheckEwsConfigGQL,
    private updateActivityPriorityGQL: UpdateActivityPriorityGQL,
    private getTemplatesGQL: GetTemplatesGQL,
  ) {
  }

  /**********************************/

  /* Queries */
  public activityByPk(id: number): Observable<any> {
    return this.activityByPkGQL.watch({id}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getActivitiesPerDate(date: string, templateId: number): Observable<any> {
    return this.activitiesPerDateGQL.watch({
      in_clipboard: false,
      date,
      templateId,
    }, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getEwsAppointments(date: String, timezone: String): Observable<any> {
    return this.ewsAppointmentsGQL.watch({date, timezone}, {fetchPolicy: 'no-cache'}).valueChanges
      .pipe(map((result) => {
        let items = [];
        if (result.data.ewsAppointments.length) {
          items = result.data.ewsAppointments.forEach((appointment) => {
            appointment.startTime = DateTime.fromISO(appointment.startTime);
            appointment.endTime = DateTime.fromISO(appointment.endTime);
            appointment.provider = 'ews';
          });
        }
        return items;
      }));
  }

  public getEwsConfig() {
    return this.getEwsConfigGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getEwsExchangeVersions() {
    return this.getEwsExchangeVersionsGQL.watch().valueChanges;
  }

  public getCalendarSelectionConfig(): Observable<any> {
    return this.getCalendarSelectionConfigGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getGlobalSettings(): Observable<any> {
    return this.getGlobalSettingsGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getPriorities(): Observable<any> {
    return this.getPrioritiesGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public getTemplates(): Observable<any> {
    return this.getTemplatesGQL.watch({}, {fetchPolicy: 'no-cache'}).valueChanges;
  }

  public checkEwsConfig(url: String, user: String, password: String, exchange_version: String): Observable<any> {
    return this.checkEwsConfigGQL.watch({
      url,
      user,
      password,
      exchange_version,
    }, {fetchPolicy: 'no-cache', errorPolicy: 'all'}).valueChanges;
  }

  public newActivityToPhase(
    date: DateTime,
    description: string,
    duration: number,
    phaseId: number,
    fixedStart: DateTime,
  ): Observable<any> {
    return this.newActivityToPhaseGQL.mutate(
      {
        activity: {
          date,
          description,
          duration,
          phaseId,
          fixedStart,
        },
      },
    );
  }

  public updateActivity(activity: Activity): Observable<any> {
    return this.updateActivityGQL.mutate({
      id: activity.id,
      note: activity.note,
      description: activity.description,
      duration: +activity.duration, // casting to number
      done: activity.done,
      fixedStart: activity.fixedStart?.toFormat('HH:mm:ss'),
      in_clipboard: activity.in_clipboard || false,
    });
  }

  public deleteActivityFromList(id: number): Observable<any> {
    return this.deleteActivityFromListGQL.mutate({id}).pipe(map((result) => {
      return result['data']['deleteActivityFromList'];
    }));
  }

  // TO-DO: delete if does not use
  public removeActivityFromList(id: number): Observable<any> {
    return this.removeActivityFromListGQL.mutate({id}).pipe(map((result) => {
      return result['data']['removeActivityFromList'];
    }));
  }

  public cloneActivity(id: number): Observable<any> {
    return this.cloneActivityGQL.mutate({id});
  }

  public moveActivityById(
    activityId: number, toActivityId: number, dir: string,
  ): Observable<any> {
    return this.moveActivityByIdGQL.mutate({
      id: activityId,
      toId: toActivityId,
      dir,
    });
  }

  public insertNewDayFromTemplate(templateId: number, date: string) {
    return this.insertNewDayFromTemplateGQL.mutate({templateId, date});
  }

  public createTemplate(name: string, active: boolean): Observable<any> {
    return this.createTemplateGQL.mutate({name, active});
  }

  public updateTemplate(templateId: number, name: string, active: boolean) {
    return this.updateTemplateGQL.mutate({id: templateId, name, active});
  }

  public deleteTemplate(id: number) {
    return this.deleteTemplateGQL.mutate({id});
  }

  public cloneTemplate(id: number, name: string): Observable<any> {
    return this.cloneTemplateGQL.mutate({id, name});
  }

  public deleteDay(date: string): Observable<any> {
    return this.deleteDayGQL.mutate({date});
  }

  public createPhase(date: string, name: string, fixedStart: string, templateId?: number): Observable<any> {
    return this.createPhaseGQL.mutate({
      date,
      name,
      fixedStart,
      duration: 0,
      templateId,
    });
  }

  public updatePhase(id: number, fixedStart?: DateTime, name?: string, orderActivities?: string): Observable<any> {
    return this.updatePhaseGQL.mutate({
      id,
      fixedStart: fixedStart?.toFormat('HH:mm:ss'),
      name,
      orderActivities,
    });
  }

  public deletePhase(id: number): Observable<any> {
    return this.deletePhaseGQL.mutate({id});
  }

  public createAppointment(appointment: Appointment, dir: string, activityId: number): Observable<any> {
    return this.createAppointmentGQL.mutate(
      // {appointment: { date: date, description: description, duration: duration, phaseId: phaseId }}
      {
        appointment: {
          summary: appointment.summary,
          startTime: appointment.startTime.toISO({suppressMilliseconds: true}),
          endTime: appointment.endTime.toISO({suppressMilliseconds: true}),
          extId: appointment.extId,
          extLink: appointment.extLink,
          provider: appointment.provider,
        },
        dir,
        activityId,
      },
    );
  }

  public insertCalendarToConfig(calendarId: String, enabled: boolean) {
    return this.insertCalendarToSelectionConfigGQL.mutate({calendar_id: calendarId, enabled});
  }

  public updateCalendarInConfig(calendarId: String, enabled: boolean) {
    return this.updateCalendarConfigGQL.mutate({calendar_id: calendarId, enabled});
  }

  public updateGlobalSetting(key: String, value: String) {
    return this.updateGlobalSettingGQL.mutate({key, value});
  }

  public insertGlobalSetting(key: String, value: String) {
    return this.insertGlobalSettingGQL.mutate({key, value});
  }

  public upsertGlobalSetting(key: String, value: String) {
    return this.upsertGlobalSettingGQL.mutate({key, value});
  }

  public insertEwsConfig(ewsConfig: EwsConfig): Observable<any> {
    return this.insertEwsConfigGQL.mutate({
      ews_url: ewsConfig.ews_url,
      ews_user: ewsConfig.ews_user,
      ews_password: ewsConfig.ews_password,
      ews_exchange_version: ewsConfig.ews_exchange_version,
    });
  }

  public updateEwsConfig(ewsConfig: EwsConfig): Observable<any> {
    return this.updateEwsConfigGQL.mutate({
      id: ewsConfig.id,
      ews_url: ewsConfig.ews_url,
      ews_user: ewsConfig.ews_user,
      ews_password: ewsConfig.ews_password,
      ews_exchange_version: ewsConfig.ews_exchange_version,
    });
  }

  /**********************************/
}
