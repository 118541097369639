<div class="c-assignedTask">
  <div class="c-assignedTask__title" [matMenuTriggerFor]="menu">
    <ng-container *ngIf="!trigger else customTrigger">
      <ng-container *ngIf="!!(activityGroup && activityGroupTask) else notAssignedTask">
        <span [style.background]="activityGroup.background" class="c-assignedTask__titleDot"></span>
        <span class="c-assignedTask__titleText">
          {{activityGroup.title}}
          <ng-container *ngIf="activityGroupTask.description !== 'default'">- {{activityGroupTask.description}}</ng-container>
        </span>
      </ng-container>

      <ng-template #notAssignedTask>
        <span class="text-gray">{{'Assign task' | translate}}</span>
      </ng-template>
    </ng-container>

    <ng-template #customTrigger>
      <ng-container [ngTemplateOutlet]="trigger"></ng-container>
    </ng-template>
  </div>

  <mat-menu #menu="matMenu" class="w-auto max-w-min content-not-padding" xPosition="after">
    <div class="c-assignedTaskGroups">
      <div class="c-assignedTaskGroups__header">
        <p class="c-assignedTaskGroups__title">{{'Select a task to assign' | translate}}</p>
        <ng-container *ngIf="!!activity.phase">
          <span *ngIf="!!activityGroupTask" class="c-assignedTaskGroups__remove" (click)="removeTaskBinding()">
            {{'Unassign task' | translate}}
          </span>
        </ng-container>
      </div>

      <ul class="c-assignedTaskGroups__list">
        <li class="c-assignedTaskGroups__listItem" *ngFor="let group of groupsWithTasks">
          <p class="c-assignedTaskGroups__listItemHeader"
             (click)="$event.stopPropagation(); group.expanded = !group.expanded">
            <span class="flex flex-1 items-center justify-between">
              <span class="c-assignedTaskGroups__listItemTitle" [class.active]="group.id === activityGroup?.id">
                <span [style.color]="group.background"
                      class="c-assignedTaskGroups__listItemHeaderDot">•</span> {{group.title}}
              </span>
              <span class="c-assignedTaskGroups__listItemHeaderCount">
                {{ '{ count, plural, one{{count} task} few{{count} tasks} other{{count} tasks} }' | translate: {count: group.tasks.length} }}
              </span>
            </span>

            <mat-icon class="c-assignedTaskGroups__listItemToggle"
                      svgIcon="expand-more"
                      [ngClass]="{'expanded': group.expanded}">
            </mat-icon>
          </p>

          <ul *ngIf="group.expanded" class="c-assignedTaskGroups__listTasks">
            <li *ngFor="let task of group.tasks" (click)="assignTaskToActivity(task, group)"
                class="c-assignedTaskGroups__listTasksItem"
                [class.active]="task.id === activityGroupTask?.id">
              <p>
                <mat-icon *ngIf="task.id === activityGroupTask?.id" class="c-assignedTaskGroups__listTasksItemDot">
                  check
                </mat-icon>
                {{task.description}}</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </mat-menu>
</div>
