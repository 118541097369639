import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getSupportedLangs, Lang } from './translate-helper';

@Injectable({providedIn: 'root'})
export class PreferredLanguageService {

  constructor(
    private translateService: TranslateService,
    ) {
  }

  getBrowserLanguage(): Lang | undefined | null {
    return this.validateLanguageCode(this.translateService.getBrowserLang())
      ? this.translateService.getBrowserLang() : null;
  }

  validateLanguageCode(code: string | undefined): boolean {
    return !!code && getSupportedLangs().includes(code);
  }
}
