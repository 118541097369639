import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateActivityGQL extends Mutation {
  document = gql`
  mutation MyMutation($id: bigint!, $description: String, $duration: Int!, $done: Boolean, $fixedStart: time, $note: String = null, $in_clipboard: Boolean) {
    update_activities_by_pk(pk_columns: {id: $id}, _set: {description: $description, duration: $duration, done: $done, fixedStart: $fixedStart, note: $note, in_clipboard: $in_clipboard}) {
      id
      appointment {
        id
        summary
        startTime
        endTime
        extId
        extLink
      }
    }
  }
  `;
}
