<div *ngIf="visible"
     id="onboardingTooltips"
     class="c-onboardingTooltips"
     [style]="position">
  <div class="c-onboardingTooltips__header">
    <p class="c-onboardingTooltips__title">{{currentTooltipData.title | translate}}</p>
    <mat-icon svgIcon="close" class="c-onboardingTooltips__close"
              (click)="finish()"></mat-icon>
  </div>
  <div class="c-onboardingTooltips__body">
    <p class="c-onboardingTooltips__count">{{currentStepNumber + 1}}/{{lastStepNumber + 1}} {{'steps' | translate}}</p>
    <p class="c-onboardingTooltips__description">{{currentTooltipData.description | translate}}</p>
  </div>
  <div class="c-onboardingTooltips__footer">
    <button *ngIf="currentStepNumber !== 0"
            class="c-onboardingTooltips__btn"
            (click)="prevStep()" matRipple>
      {{'Prev' | translate}}
    </button>

    <button *ngIf="currentStepNumber !== lastStepNumber"
            class="c-onboardingTooltips__btn"
            (click)="nextStep()" matRipple>
      {{'Next' | translate}}
    </button>

    <button *ngIf="currentStepNumber === lastStepNumber"
            (click)="finish()"
            class="c-onboardingTooltips__btn" matRipple>
      {{'Finish' | translate}}
    </button>
  </div>
</div>
