import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Activity } from '../../core/models/interfaces';
import {
  addActivityToClipboardStoreAction,
  deleteActivityFromClipboardStoreAction,
  loadActivitiesTreeSuccessAction,
  updateActivityInStoreAction,
} from './clipboard-activities.actions';

export interface ActivitiesState extends EntityState<Activity> { }

const adapter: EntityAdapter<Activity> = createEntityAdapter<Activity>({
  selectId: (item: Activity) => item.id,
  sortComparer: false,
});

const initialState: ActivitiesState = adapter.getInitialState({});

const _activitiesStoreReducer = createReducer<ActivitiesState>(
  initialState,
  on(addActivityToClipboardStoreAction, (state, { activity }) => {
    return adapter.addOne(activity, state);
  }),
  on(loadActivitiesTreeSuccessAction, (state, { responce }) => {
    return adapter.upsertMany(responce, state);
  }),
  on(updateActivityInStoreAction, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(deleteActivityFromClipboardStoreAction, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
);

export function clipboardActivitiesStoreReducer(state: any, action: Action): ActivitiesState {
  return _activitiesStoreReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of Activities ids
export const selectUserIds = selectIds;

// select the dictionary of Activities entities
export const selectActivitiesEntities = selectEntities;

// select the array of Activities
export const selectAllActivities = selectAll;

// select the total Activities count
export const selectActivitiesTotal = selectTotal;
