import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetTemplatesGQL extends Query<any> {
  document = gql`
    query templates {
      templates {
        id
        default
        name
        active
      }
    }
  `;
}
