import { createAction, props } from '@ngrx/store';
import { Activity } from '../../core/models/interfaces';
import { Predicate, Update } from '@ngrx/entity';
import { ToastTypes } from '../../core/services/notification.service';

// add/update
export const addActivityInStoreAction = createAction('[Activities] Add Activity In Store Action', props<{ activity: Activity }>());
export const setManyActivitiesInStoreAction = createAction('[Activities] Update Activities In Store Action', props<{ activities: Activity[] }>());
export const updateManyActivitiesInStoreAction = createAction('[Activities] Update Many Activities In Store Action', props<{ updates: Update<Activity>[] }>());
export const updateTaskActivityAction = createAction('[Activities] Update Task Activity', props<{ oldActivity: Activity; activity: Activity; msg: string; }>());

export const updateActivityAction = createAction('[Activities] Update Activity Action', props<{ activity: Activity; msg: string; }>());
export const updateActivityInStoreAction = createAction('[Activities] Update Activity In Store Action', props<{ update: Update<Activity> }>());
export const updateActivityPriorityAction = createAction('[Activities] Update Activity Priority', props<{ changes: {
    templateId: number;
    date: any;
    activityId: number;
    priorityId: number;
}; msg: string; }>());

export const cloneActivityAction = createAction('[Activities] Clone Activity', props<{ activity: Activity; msg: string; }>());

// delete
export const deleteActivitiesByIdsAction = createAction('[Activities] Delete Activities By Ids', props<{ ids: number[] }>());
export const deleteActivitiesByPredicateAction = createAction('[Activities] Delete Activities By Predicate', props<{ predicate: Predicate<Activity> }>());
export const deleteActivityAction = createAction('[Activities] Delete Activity', props<{ activity: Activity; msg: string; }>());
export const deleteActivityInStoreAction = createAction('[Activities] Delete Activity In Store', props<{ id: number; }>());

// extra actions
export const sortDayFromActivitiesStoreAction = createAction('[Activities] Sort Day From Activities Store', props<{ date: any; templateId: number; msg: string; }>());
export const showToastActivitiesAction = createAction('[Activities] Show Toast Activities', props<{ toastType: ToastTypes; errorMsg: string; }>());
