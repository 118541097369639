import { Component, Input, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { Activity, Priority } from '../../../../core/models/interfaces';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AssignedGroupContainer, ActivityStatus, PlanningBoardClipboardParent, sortStep } from '../planning-board/helpers/planning-board.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlanningBoardClipboardService } from '../planning-board/services/planning-board-clipboard.service';
import { take } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { PlanningBoardActivityComponent } from '../planning-board/components/planning-board-activity/planning-board-activity.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ActivitiesService } from '../planning-board/services/activities.service';
import { GroupContainersService } from '../planning-board/services/group-containers.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Observable } from 'rxjs';

import { selectAllActivities } from '../../../../store/clipboard-activities/clipboard-activities.selectors';
import { loadeActivitiesTreeAction } from '../../../../store/clipboard-activities/clipboard-activities.actions';
import { NotificationService } from '../../../../core/services/notification.service';
import { selectAllPriorities } from '../../../../store/priorities/priorities.selectors';

const PlanningBoardClipboardStorageSizeKey = 'planning-board-size';

@UntilDestroy()
@Component({
    selector: 'app-clipboard',
    templateUrl: './clipboard.component.html',
    styleUrls: ['./clipboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CdkDropList,
        NgIf,
        NgFor,
        PlanningBoardActivityComponent,
        MatIcon,
        AsyncPipe,
        TranslateModule,
    ],
})
export class ClipboardComponent implements OnInit {
  @Input() parent: PlanningBoardClipboardParent = PlanningBoardClipboardParent.PlanningBoard;

  public activities: Activity[] = [];
  public groupsWithTasks: AssignedGroupContainer[] = [];
  public clipboardSize = !!localStorage.getItem(PlanningBoardClipboardStorageSizeKey)
    ? localStorage.getItem(PlanningBoardClipboardStorageSizeKey) + 'px' : '33%';
  public clipboardVisible$ = this.planningBoardClipboardService.clipboardVisible$;
  public activityStatuses: ActivityStatus[] = [];
  public priorities = signal<Priority[]>([]);

  public activities$: Observable<Activity[]> = this.store.select(selectAllActivities);

  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activitiesService: ActivitiesService,
    private groupContainersService: GroupContainersService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
  ) {
    this.store.dispatch(loadeActivitiesTreeAction());
  }

  ngOnInit(): void {
    this._getProperties();
    this.fetchGroupsWithTask();

    // this.fetchStatuses();
    // this.getClipboardTasks();
  }

  fetchStatuses() {
    this.activitiesService.fetchStatuses$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((statuses) => {
        this.activityStatuses = statuses;
      });
  }

  public fetchGroupsWithTask(): void {
    this.groupContainersService.fetchGroupsWithTask()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((groups) => {
        this.groupsWithTasks = groups;
      });
  }

  toggleClipboardVisibility() {
    this.planningBoardClipboardService.toggleVisibility(!this.planningBoardClipboardService.clipboardVisible$.getValue());
  }

  public cloneActivity(activity: Activity): void {
    this.planningBoardClipboardService.cloneClipboardActivity(activity)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notificationService.success({
            description: this.translate.instant(marker('Clipboard activity cloned successfully')),
          });
        },
        error: (error) => {
          this.notificationService.error({ description: error });
        },
      });
  }

  public changeClipboardSize(): void {
    const planningBoardWrapper: HTMLElement = document.querySelector('.c-planningBoardClipboard__wrapper');

     document.addEventListener('mousemove', setClipboardSize, false);

    function setClipboardSize(event) {
      const clipboardSize = window.innerWidth - event.clientX;
      planningBoardWrapper.style.transition = 'none';
      planningBoardWrapper.style.width = clipboardSize + 'px';
      localStorage.setItem(PlanningBoardClipboardStorageSizeKey, clipboardSize.toString());
    }

    document.addEventListener('mouseup', (event) => {
      planningBoardWrapper.style.transition = 'var(--ex-transition-primary)';
      document.removeAllListeners();
    }, false);
  }

  public drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer !== event.container || event.currentIndex === event.previousIndex) {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    const toTask = this.activities[event.currentIndex];

    toTask.order_in_clipboard = this.generateArithmeticMeanSorting(event.currentIndex);

    this.activitiesService.updateActivityByPk$(toTask)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => {},
        error: () => {
          if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
          } else {
            transferArrayItem(
              event.container.data,
              event.previousContainer.data,
              event.currentIndex,
              event.previousIndex,
            );
          }
        },
      });
  }

  private _getProperties(): void {
    this.store.select(selectAllPriorities)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (priorities) => {
          this.priorities.set(priorities);
        },
      });
  }

  private generateArithmeticMeanSorting(currentIndex) {
    const previousTaskBeforeToTask = this.activities[currentIndex - 1];
    const nextTaskAfterToTask = this.activities[currentIndex + 1];

    let arithmeticMeanSorting = sortStep;

    if (currentIndex !== 0 && currentIndex !== this.activities.length - 1) {
      arithmeticMeanSorting = (nextTaskAfterToTask.order_in_clipboard + previousTaskBeforeToTask.order_in_clipboard) / 2;
    } else {
      if (this.activities.length > 1) {
        if (currentIndex === 0) {
          arithmeticMeanSorting = nextTaskAfterToTask.order_in_clipboard / 2;
        }

        if (currentIndex === this.activities.length - 1) {
          arithmeticMeanSorting = previousTaskBeforeToTask.order_in_clipboard + sortStep;
        }
      }
    }
    return arithmeticMeanSorting;
  }
}
