import { createAction, props } from '@ngrx/store';
import { Priority } from '../../core/models/interfaces';
import { ToastTypes } from '../../core/services/notification.service';

// load
export const loadePrioritiesTreeAction = createAction('[Priorities] Load Priorities Tree');
export const loadPrioritiesTreeSuccessAction = createAction('[Priorities] Load Priorities Success', props<{ responce: Priority[] }>());

// extra actions
export const showToastPrioritiesAction = createAction('[Priorities] Show Toast Priorities', props<{ toastType: ToastTypes; errorMsg: string; }>());
