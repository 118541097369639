import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateGroupContainerStatusGQL extends Mutation {
  document = gql`
  mutation UPDATE_GROUP_CONTAINER_STATUS_BY_PK_GQL($id: bigint!, $status: String) {
    update_groups_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
      id
      title
    }
  }
  `;
}
