import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Activity, Priority } from '../../../../core/models/interfaces';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { ngxMaterialTimepickerTheme } from '../../../../_helpers/helpers';
import { QuillModule, QuillModules } from 'ngx-quill';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { DurationEntryComponent } from '../../../../core/components/objects/duration-entry/duration-entry.component';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatIcon } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule, UntypedFormBuilder } from '@angular/forms';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { ActivitiesService } from '../planning-board/services/activities.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { updateActivityAction } from '../../../../store/activities/activities.actions';

const QUILL_MODULES: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image'],
  ],
};

@UntilDestroy()
@Component({
    selector: 'activity-entry-management',
    templateUrl: './activity-entry-management.component.html',
    styleUrls: ['./activity-entry-management.component.scss'],
    standalone: true,
    imports: [
        MatMenuTrigger,
        MatMenu,
        NgFor,
        NgClass,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        MatIcon,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        DurationEntryComponent,
        QuillModule,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class ActivityEntryManagementComponent implements AfterViewInit {
  private _activity!: Activity;
  @Input()
  get activity(): Activity {
    return this._activity;
  }
  set activity(activity: Activity) {
    this._activity = activity;
    this.form.setValue({ quillEditorControl: this._activity.note });
  }
  @Input() priorities!: Priority[];

  public form = this.formBuilder.group({
    quillEditorControl: [null],
  });

  public theme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;
  public modules: QuillModules = QUILL_MODULES;

  constructor(
    private _store: Store<AppState>,
    private activityManagementService: ActivityManagementService,
    private _activitiesService: ActivitiesService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private userLocaleService: UserLocaleService,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  get timeFormat() {
    return this.userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  fixedStartChanged(event: Event) {
    if (event && event.toString() !== '') {
      const format = `${this.userLocaleService.format.date} ${this.userLocaleService.format.time}`;
      this._activity.fixedStart = DateTime.fromFormat(this._activity.date.toFormat(this.userLocaleService.format.date) + ' ' + event.toString(), format);
    } else {
      this._activity.fixedStart = undefined;
    }
    this.updateActivity();
  }

  public clearFixedStartChanged(): void {
    this._activity.fixedStart = undefined;
    this.updateActivity();
  }

  updatePriority(priority: Priority) {
    if (this._activity.priority.id === priority.id) {
      return;
    }

    this._activitiesService.updateActivityPriority(this._activity.id, priority.id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this._activity.priority = res.data.update_activities_by_pk.priority;
        this.notificationService.success({
          description: this.translate.instant(marker('Priority changed')),
        });
      });
  }

  public setActivityNote(content): void {
    this._activity.note = this.form.get('quillEditorControl').getRawValue();
    this.notificationService.success({
      description: this.translate.instant(marker('Activity note is updated')),
    });
  }

  public updateDuration(value: number): void {
    this._activity.duration = value;
    this.updateActivity();
  }

  public updateActivity(): void {
     this._store.dispatch(updateActivityAction({
      activity: { ...this.activity },
      msg: 'Task successfully changed',
    }));
  }

  public closeActivityInfo(): void {
    this.activityManagementService.setActivityData(null);
  }
}
