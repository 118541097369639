import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeletePhaseGQL extends Mutation {
  document = gql`
    mutation deletePhase($id: BigInt!){
      deletePhase (id: $id)
      {
        removedActivities {
            id
        }
        changedActivities {
            i
            p
            n
            ph
            date
        }
        errors
      }
    }
  `;
}
