import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Template } from '../../core/models/interfaces';
import * as fromPhaseTemplatesReducer from './phase-templates.reducer';

export const selectFeature = createFeatureSelector<AppState>('phaseTemplates');

export const selectAllPhaseTemplates = createSelector<AppState, any, Template[]>(
  selectFeature,
  fromPhaseTemplatesReducer.selectAllPhaseTemplates,
);

export const selectSelectedTemplateId = createSelector<AppState, any, string | number>(
  selectFeature,
  fromPhaseTemplatesReducer.getSelectedTemplateId,
);

export const selectPhaseTemplateEntities = createSelector<AppState, any, any>(
  selectFeature,
  fromPhaseTemplatesReducer.selectPhaseTemplatesEntities,
);

export const selectTemplateBySelectedId = createSelector(
  selectPhaseTemplateEntities,
  selectSelectedTemplateId,
  (templatesEntities, templateId) => templatesEntities[templateId] || null,
);

export const selectTemplate = (templateId: number) => createSelector(
  selectPhaseTemplateEntities,
  (templatesEntities) => templatesEntities[templateId],
);
