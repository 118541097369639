import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Template } from '../../../../../../core/models/interfaces';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppButtonComponent } from '../../../../../../core/components/app-button/app-button.component';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'select-template-dialog',
    templateUrl: './select-template-dialog.component.html',
    styleUrls: ['./select-template-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatIcon,
        MatFormField,
        MatLabel,
        MatSelect,
        ReactiveFormsModule,
        NgFor,
        MatOption,
        NgIf,
        MatError,
        AppButtonComponent,
        TranslateModule,
    ],
})
export class SelectTemplateDialog {
  public selectedTemplateId: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { templates: Template[] },
    public dialogRef: MatDialogRef<SelectTemplateDialog>,
  ) { }

  public onOk(): void {
    if (this.selectedTemplateId.valid) {
      this.dialogRef.close({id: this.selectedTemplateId.value});
    }
  }
}
