<div class="c-activityManagement">
  <div class="c-activityManagement__header">
    <div class="c-activityManagement__descriptionWrapper">
      <p class="c-activityListEntry__prioritiesItem {{activity.priority.description}}"
         [matMenuTriggerFor]="priorityDropdown">
        <span class="c-activityListEntry__prioritiesItemDot"></span>
      </p>
      <mat-menu #priorityDropdown="matMenu" xPosition="before" yPosition="above">
        <div class="c-activityListEntry__prioritiesWrapper">
          <p class="c-activityListEntry__prioritiesTitle">{{'Priority' | translate}}</p>
          <ul class="c-activityListEntry__priorities">
            <li *ngFor="let priority of priorities"
                (click)="updatePriority(priority)"
                class="c-activityListEntry__prioritiesItem {{priority.description}}"
                [ngClass]="{'active': activity.priority.id === priority.id}">
              <span class="c-activityListEntry__prioritiesItemDot"></span>
            </li>
          </ul>
        </div>
      </mat-menu>
      <input type="text"
             [value]="activity.description"
             [(ngModel)]="activity.description"
             (change)="updateActivity()" (keyup.enter)="activityDescription.blur()"
             [placeholder]="'Enter activity description...' | translate"
             class="c-activityManagement__description" #activityDescription>
    </div>
    <div class="flex items-center">
      <div *ngIf="activity.done" class="c-activityManagement__status">
        {{'done' | translate}}
      </div>
      <button class="c-activityManagement__close" (click)="closeActivityInfo()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="c-activityManagement__body">
    <p class="c-activityManagement__caption">{{'Time' | translate}}: {{activity.actStart | dateTimeFormat}} - {{activity.actEnd | dateTimeFormat}}</p>
    <div class="flex items-center justify-between mb-5">
      <div class="c-activityListEntry__selectWrapper c-activityListEntry__selectWrapper--column">
        <p class="c-activityListEntry__selectTitle">{{'Fixed start' | translate}}:</p>
        <div class="c-activityListEntry__fixedStart" [ngClass]="{'ampm': +timeFormat === 12}">
          <p class="whitespace-nowrap">{{activity.fixedStart ? (activity.fixedStart | dateTimeFormat) : '- - : - -'}}</p>
          <mat-icon svgIcon="schedule"
                    class="c-activityListEntry__fixedStartIcon"
                    [ngxTimepicker]="picker"
                    [ngModel]="activity.fixedStart | dateTimeFormat"
                    (ngModelChange)="fixedStartChanged($event)"
                    [format]="+timeFormat"
                    [value]="activity.fixedStart | dateTimeFormat"></mat-icon>
          <ngx-material-timepicker disableAnimation="true"
                                   [cancelBtnTmpl]="cancelBtnTmpl"
                                   [confirmBtnTmpl]="confirmBtnTmpl"
                                   [theme]="theme"
                                   [minutesGap]="5"
                                   #picker></ngx-material-timepicker>
          <ng-template #cancelBtnTmpl>
            <app-button btnStyle="outline">{{'Cancel' | translate}}</app-button>
          </ng-template>
          <ng-template #confirmBtnTmpl>
            <app-button class="ml-3 block" style="min-width: 112px;">{{'Set' | translate}}</app-button>
          </ng-template>
          <button *ngIf="!!activity.fixedStart" class="c-activityListEntry__fixedStartClear"
                  (click)="clearFixedStartChanged()">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </div>
      </div>

      <div class="c-activityListEntry__selectWrapper c-activityListEntry__selectWrapper--column">
        <p class="c-activityListEntry__selectTitle">{{'Duration' | translate}}:</p>
        <duration-entry (durationChanged)="updateDuration($event)" [currentDuration]="activity.duration" [column]="true"></duration-entry>
      </div>

    </div>

    <form [formGroup]="form">
      <p class="c-activityManagement__title">{{'Description' | translate}}:</p>
      <quill-editor #editor
                    formControlName="quillEditorControl"
                    format="html"
                    [styles]="{height: '280px'}"
                    [placeholder]="'Insert a larger description here...' | translate"
                    [modules]="modules"
                    theme="snow"
                    [sanitize]="true">
      </quill-editor>
    </form>

    <div class="flex items-center mt-3">
      <app-button (click)="setActivityNote(editor.content); updateActivity()">{{'Save' | translate}}</app-button>
      <app-button btnStyle="outline" class="ml-2.5" (click)="editor.content = activity.note">{{'Cancel' | translate}}</app-button>
    </div>
  </div>
</div>
