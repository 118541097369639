import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

@Injectable({providedIn: 'root'})
export class NgProgressService {
  progressRef: NgProgressRef;

  constructor(
    private progress: NgProgress,
  ) {
    this.progressRef = progress.ref('globalProgressbar');
  }

  start() {
    this.progressRef.start();
  }

  complete() {
    this.progressRef.complete();
  }
}
