<div class="c-dialog c-dialog--bigger c-dialog--onboarding">
  <button class="c-dialog__close" type="button" (click)="closeDialog()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <div class="c-dialog__body">
    <p class="c-dialog__title">{{'Welcome to Planmy.day' | translate}}</p>
    <ul>
      <li>{{'Plan your day and you will get more things done in less time – 12 minutes planning will get you 2 hours in time savings' | translate}}</li>
      <li>{{'Plan your day in the morning or in the evening for the next day.' | translate}}</li>
      <li>{{'Update your plan constantly to get a log of your achievements during the day' | translate}}</li>
      <li>{{'Set time budgets for activities to get focused and get things done quickly' | translate}}</li>
    </ul>
  </div>
  <div class="c-dialog__footer">
    <div class="c-dialog__footerBtns">
      <app-button (click)="closeDialog()">{{'Let’s get started!' | translate}}</app-button>
    </div>
  </div>
</div>
