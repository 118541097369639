import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class MoveActivityToAnotherTaskGQL extends Mutation {
  document = gql`
    mutation MoveActivityToAnotherTaskGQL($id: bigint!, $task_id: uuid, $order_in_task: Int) {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {task_id: $task_id, order_in_task: $order_in_task}) {
        id
      }
    }
  `;
}
