import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateTaskByPkGQL extends Mutation {
  document = gql`
  mutation UpdateTaskByPkGQL(
    $id: uuid!,
    $description: String,
    $duration: Int,
    $status: String,
    $priority_id: bigint,
    $style: String,
    $clipboard: Boolean
  ) {
    update_tasks_by_pk(
      pk_columns: {id: $id},
      _set: {
        style: $style,
        status: $status,
        duration: $duration,
        description: $description,
        priority_id: $priority_id,
        clipboard: $clipboard
      }
    ) {
      id
    }
  }`;
}
