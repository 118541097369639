import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Template } from '../../../../../../core/models/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AppButtonComponent } from '../../../../../../core/components/app-button/app-button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'edit-template-dialog',
    templateUrl: './edit-template-dialog.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatIcon,
        MatFormField,
        MatLabel,
        MatInput,
        MatError,
        MatCheckbox,
        AppButtonComponent,
        TranslateModule,
    ],
})
export class EditTemplateDialog implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EditTemplateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { template: Template },
  ) {
  }

  ngOnInit(): void {
    if (this.data.template !== undefined) {
      this.form = this.formBuilder.group({
        templateName: [this.data.template.name, Validators.required],
        templateActive: [this.data.template.active],
      });
    } else {
      this.form = this.formBuilder.group({
        templateName: ['', Validators.required],
        templateActive: [true],
      });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.data.template !== undefined) {
        this.dialogRef.close({
          id: this.data.template.id,
          name: this.form.value.templateName,
          active: this.form.value.templateActive,
        });
      } else {
        this.dialogRef.close({
          name: this.form.value.templateName,
          active: this.form.value.templateActive,
        });
      }
    }
  }
}
