import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ActivityByPkGQL extends Query<Response> {
  document = gql`
    query activities_by_pk($id: bigint!) {
      activities_by_pk(id: $id) {
        id
        note
        date
        phaseDefault
        nextActivityId
        previousActivityId
        duration
        done
        description
        fixedStart
        templateId
        in_clipboard
        order_in_clipboard
        order_in_phase
        phase {
          id
        }
        priority {
          id
          description
        }
        task {
          id
          is_implicit
          description
          group {
            id
            title
          }
        }
      }
    }
  `;
}
