import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteDayGQL extends Mutation {
  document = gql`
    mutation deleteDay ($date: ISO8601Date!) {
        deleteDay (date: $date)
        {
            date
            errors
        }
    }
    `;
}
