import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CreateAppointmentGQL extends Mutation {
  document = gql`
    mutation createAppointment($appointment: AppointmentInput!, $dir: MoveActivityDir!, $activityId: BigInt!) {
      createAppointment(appointment: $appointment, dir: $dir, activityId: $activityId) {
        activity {
          id
          description
          fixedStart
          date
          duration
          phaseDefault
          phase {
            id
          }
          priority {
            id
            description
          }
          appointment {
            id
            summary
            startTime
            endTime
            extId
            extLink
          }
          previousActivityId
          nextActivityId
        }
        changedActivities {
          i
          p
          n
          ph
        }
      }
    }`;
}
