import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class DeleteGroupContainerByPkGQL extends Mutation {
  document = gql`
  mutation DeleteGroupContainerByPk($id: bigint!) {
    delete_groups_by_pk(id: $id) {
      id
    }
  }`;
}
