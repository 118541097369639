import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { CommonToastData } from './common-toast.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatRipple } from '@angular/material/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-common-toast',
    templateUrl: './common-toast.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatRipple,
        TranslateModule,
    ],
})
export class CommonToastComponent {
  constructor(
    @Inject(HotToastRef) public toastRef: HotToastRef<CommonToastData>,
  ) {}

  closeToast() {
    this.toastRef.close();
  }
}
