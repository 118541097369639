import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class InsertCalendarToSelectionConfigGQL extends Mutation {
  document = gql`
    mutation insert_calendar_selection_config_one($enabled: Boolean = true, $calendar_id: String = "") {
      insert_calendar_selection_config_one(object: {calendar_id: $calendar_id, enabled: $enabled}) {
        id
      }
    }`;
}
