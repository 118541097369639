import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Activity } from '../../core/models/interfaces';
import {
  addActivityInStoreAction,
  deleteActivitiesByIdsAction,
  deleteActivitiesByPredicateAction,
  deleteActivityInStoreAction,
  setManyActivitiesInStoreAction,
  updateActivityInStoreAction,
  updateManyActivitiesInStoreAction,
} from './activities.actions';

export interface ActivitiesState extends EntityState<Activity> {
  selectedPhaseId: string | number | null;
}

const adapter: EntityAdapter<Activity> = createEntityAdapter<Activity>({
  selectId: (item: Activity) => item.id,
  sortComparer: false,
});

const initialState: ActivitiesState = adapter.getInitialState({
  selectedPhaseId: null,
});

const _activitiesStoreReducer = createReducer<ActivitiesState>(
  initialState,
  on(addActivityInStoreAction, (state, { activity }) => {
    return adapter.addOne(activity, state);
  }),
  on(setManyActivitiesInStoreAction, (state, { activities }) => {
    return adapter.setMany(activities, state);
  }),
  on(updateActivityInStoreAction, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(updateManyActivitiesInStoreAction, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(deleteActivitiesByIdsAction, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(deleteActivitiesByPredicateAction, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(deleteActivityInStoreAction, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
);

export function activitiesStoreReducer(state: any, action: Action): ActivitiesState {
  return _activitiesStoreReducer(state, action);
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors();

export const getSelectedActivityId = (state: ActivitiesState): string | number => state.selectedPhaseId;
export const selectActivitiesEntities = selectEntities;
export const selectAllActivities = selectAll;
