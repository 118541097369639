import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Activity } from '../../../../../core/models/interfaces';
import { ActivityManagementService } from '../../../../../core/services/activity-management.service';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeFormatPipe } from '../../../../../shared/pipes/date-time-format.pipe';
import { NgIf } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { QuillModule, QuillViewComponent } from 'ngx-quill';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'activity-entry-info-overlay',
    templateUrl: './activity-entry-info-overlay.component.html',
    styleUrls: ['./activity-entry-info-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        NgIf,
        DateTimeFormatPipe,
        TranslateModule,
        FormsModule,
        QuillModule,
    ],
})
export class ActivityEntryInfoOverlayComponent {
  @Input() activity!: Activity;

  constructor(private activityManagementService: ActivityManagementService) { }

  openMoreInfo() {
    this.activityManagementService.setActivityData(this.activity);
  }
}
