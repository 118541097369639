import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CreateTemplateGQL extends Mutation {
  document = gql`
    mutation createTemplate($name: String!, $active: Boolean!) {
      createTemplate(name: $name, active: $active) {
        template {
          id
          name
          active
        }
      }
    }`;
}
