import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Activity } from '../../core/models/interfaces';
import * as fromActivitiesReducer from './activities.reducer';

export const selectFeature = createFeatureSelector<AppState>('activities');

export const selectAllActivities = createSelector<AppState, any, Activity[]>(
  selectFeature,
  fromActivitiesReducer.selectAllActivities,
);

export const selectSelectedActivityId = createSelector<AppState, any, string | number>(
  selectFeature,
  fromActivitiesReducer.getSelectedActivityId,
);

export const selectActivitiesEntities = createSelector<AppState, any, any>(
  selectFeature,
  fromActivitiesReducer.selectActivitiesEntities,
);

export const selectActivityBySelectedId = createSelector(
  selectActivitiesEntities,
  selectSelectedActivityId,
  (Entities, ActivityId) => Entities[ActivityId] || null,
);

export const selectActivity = (phaseId: number) => createSelector(
  selectActivitiesEntities,
  (ActivitiesEntities) => ActivitiesEntities[phaseId],
);
