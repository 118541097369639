import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { THEME_KEYS, ThemeService } from './core/services/theme.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { ClipboardComponent } from './modules/day-planner/components/clipboard/clipboard.component';
import { Priority } from './core/models/interfaces';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgIf } from '@angular/common';
import { AvailableClipboardRoutes } from './core/constants/available-clipboard-routes';
import { NgProgressModule } from 'ngx-progressbar';
import localeUk from '@angular/common/locales/uk';
import localeDe from '@angular/common/locales/de';
import localeRu from '@angular/common/locales/ru';
import { UserLocaleService } from './shared/services/user-locale.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
      NgIf,
      NgProgressModule,
      RouterOutlet,
      RouterLinkWithHref,
      ClipboardComponent,
    ],
})
export class AppComponent implements OnInit {
  public title = 'BeMyDay';

  public priorities: Priority[] = [];
  public showClipboard: boolean = false;

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _themeService: ThemeService,
    private _router: Router,
    private _userLocaleService: UserLocaleService,
  ) {

    this._userLocaleService.registerLocaleNg([localeUk, localeDe, localeRu]);

     this._router.events
      .pipe(
          filter((event) => event instanceof NavigationStart),
          untilDestroyed(this),
      )
      .subscribe((event: NavigationStart) => {
        this.showClipboard = !event?.url.includes(AvailableClipboardRoutes.PLANNING_BOARD);
      });
  }

  ngOnInit(): void {
    this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/sprites/global.svg`));
    this._themeService.initTheme(this._themeService.isDark ? THEME_KEYS.Dark : THEME_KEYS.Light);
  }
}
