import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class AssignTaskToActivityGQL extends Mutation {
  document = gql`
    mutation MyMutation($id: bigint!, $task_id: uuid!) {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {task_id: $task_id}) {
        id
      }
    }
  `;
}
