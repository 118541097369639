import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class DeleteTaskGQL extends Mutation {
  // TODO - add remote-schema query
  document = gql`
  mutation DeleteTaskGQL(
    $id: uuid!,
  ) {
    update_tasks_by_pk(
      pk_columns: {
        id: $id
      },
      _set: {
        is_deleted: true
      }
    ) {
      id
    }
  }`;
}
