import { Component, Input, OnInit } from '@angular/core';
import { Appointment } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatIconAnchor } from '@angular/material/button';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-appointment-list-entry',
    templateUrl: './appointment-list-entry.component.html',
    standalone: true,
    imports: [
        NgIf,
        MatIconButton,
        MatTooltip,
        MatIcon,
        MatIconAnchor,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class AppointmentListEntryComponent implements OnInit {
  @Input() appointment: Appointment;

  constructor(
    private dataProviderService: DataproviderService,
    public userLocaleService: UserLocaleService,
  ) {
  }

  ngOnInit(): void {
    this.dataProviderService.lastUpdatedAppointment$
      .pipe(filter((appointment) => !!appointment && this.appointment.extId === appointment.extId), untilDestroyed(this))
      .subscribe((updatedAppointment) => {
        this.appointment.planned = updatedAppointment.planned;
      });
  }

  toActivityList() {
    this.dataProviderService.appointmentToActivityList(this.appointment);
  }
}
