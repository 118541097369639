import { Injectable } from '@angular/core';
import { Subject, tap } from 'rxjs';
import { GraphqlService } from './graphql.service';
import { Activity, Appointment, Phase } from '../models/interfaces';
import { DateTime } from 'luxon';
import { NotificationService } from './notification.service';
import { NgProgressService } from '../../shared/services/ng-progress.service';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { addActivityInStoreAction, setManyActivitiesInStoreAction } from '../../store/activities/activities.actions';
import { PhasesService } from './phases.service';
import { deepClone } from 'src/app/_helpers/helpers';

@Injectable({providedIn: 'root'})
export class DataproviderService {
  public lastUpdatedAppointment$ = new Subject<Appointment>();

  constructor(
    private _store: Store<AppState>,
    private _phasesService: PhasesService,
    private _graphqlService: GraphqlService,
    private _notificationService: NotificationService,
    private _progressService: NgProgressService,
    private _translate: TranslateService,
  ) { }

  public fetchAllActivitiesAndPhases(date: string, templateId?: number): void {
    if (this._phasesService.hasDayPhases(date, templateId) && this._phasesService.getPhasesFromMap(date, templateId)!.length > 0) {
      this._phasesService.refreshDays();
      return;
    }

    templateId === undefined ? templateId = -1 : templateId;

    this._graphqlService
      .getActivitiesPerDate(date, templateId)
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .subscribe((result) => {
        const activities = JSON.parse(JSON.stringify(result?.data?.activities));

        const updatedActivities: Activity[] = [];
        if (activities !== undefined) {
          for (const activity of activities) {
            if (activity.fixedStart) {
              activity.fixedStart = DateTime.fromSQL(
                activity.date + ' ' + activity.fixedStart,
              );
            }
            activity.date = DateTime.fromSQL(activity.date);
            activity.id = +activity.id;
            updatedActivities.push(activity);
          }

          if (updatedActivities.length) {
            this._store.dispatch(setManyActivitiesInStoreAction({ activities: updatedActivities } ));
          }
        }

        const phases = JSON.parse(JSON.stringify(result?.data?.phases));
        if (phases !== undefined) {
          for (const phase of phases) {
            if (phase.fixedStart) {
              phase.fixedStart = DateTime.fromSQL(
                date + ' ' + phase.fixedStart,
              );
            }
            phase.date = DateTime.fromSQL(phase.date);
            phase.id = +phase.id;
          }
        }

        if (phases.length > 0) {
          this._phasesService.setDayPhases(date, phases, templateId);
          this._phasesService.sortDay$(date, templateId).subscribe(() => {
            this._phasesService.refreshDays();
          });
        } else {
          this._phasesService.refreshDays();
        }
      });
  }

  public moveActivityById(prevActivity: Activity, toActivity: Activity, dir: string) {
    return this._graphqlService.moveActivityById(prevActivity.id, toActivity.id, dir)
      .pipe(tap({
        next: (result) => {
          const newActivity = deepClone(result.data.moveActivityById.activity);
          const date = newActivity.date;
          const templateId = newActivity.templateId;

          newActivity.fixedStart = !!newActivity.fixedStart ? DateTime.fromSQL(date + ' ' + newActivity.fixedStart) : null;
          newActivity.date = DateTime.fromSQL(newActivity.date);
          newActivity.id = +newActivity.id;
          newActivity.task = deepClone(prevActivity.task);

          if (prevActivity.in_clipboard) {
            newActivity.in_clipboard = false;
            newActivity.order_in_clipboard = null;
          }

          this._store.dispatch(addActivityInStoreAction({ activity: newActivity }));
          this._phasesService.updateChangedActivities(result.data.moveActivityById.changedActivities);
          this._phasesService.sortDay$(date, templateId).subscribe(() => {
            this._phasesService.refreshDays();
          });
        },
        error: () => {
          this._notificationService.error({
            description: this._translate.instant(marker('Failed to move activity')),
          });
        },
      }));
  }

  // ************************************
  // Appointment related stuff
  // ************************************

  public appointmentToActivityList(appointment: Appointment) {
    if (appointment.allDay) {
      return this._notificationService.warning({
        description: this._translate.instant(marker('All day appointments cannot be added to activities')),
      });
    }

    const date = appointment.startTime.toFormat('yyyy-MM-dd');
    if (!this._phasesService.hasDayPhases(date)) {
      return this._notificationService.warning({
        description: this._translate.instant(marker('Need to add a day first - click "Add New Day"')),
      });
    }

    if (appointment.planned) {
      return this._notificationService.warning({
        description: this._translate.instant(marker('The appointment is already inserted')),
      });
    }
    // get the extId from appointment
    // check if in activities for the current day are appointments related that have the extId

    const toInsert = this.findAppoinmentInsertPosition(
      appointment.startTime,
      this._phasesService.getPhasesFromMap(date),
    );

    this._progressService.start();
    const createAppointmentSub = this._graphqlService.createAppointment(appointment, toInsert.dir, toInsert.insertToId!)
      .subscribe({
        next: (result) => {
          appointment.planned = true;
          const act = result.data.createAppointment.activity;
          if (act.fixedStart) {
            act.fixedStart = DateTime.fromSQL(act.date + ' ' + act.fixedStart);
          }
          act.date = DateTime.fromSQL(act.date);
          act.id = +act.id;
          act.done = false;
          if (act.templateId !== undefined) {
            act.templateId = +act.templateId;
          }

          this._store.dispatch(addActivityInStoreAction({ activity: act }));
          this._phasesService.updateChangedActivities(result.data.createAppointment.changedActivities);

          this._phasesService.sortDay$(date, act.templateId).subscribe(() => {
            this._phasesService.refreshDays();
          });
          this._progressService.complete();
        },
        error: () => {
          this._notificationService.error({
            description: this._translate.instant(marker('Failed to add appointment to activities')),
          });
        },
        complete: () => createAppointmentSub.unsubscribe(),
      });
  }

  /**
   * finding the right insert position for the appointment
   * (given the start time) in the activity lists of phases
   * TODO: implement test spec
   * @param startTime the start time of the appointment
   * @param phases list of phases to search insert position
   * @returns insert direction and activity
   */
  private findAppoinmentInsertPosition(startTime: DateTime, phases: Phase[]) {
    const date = startTime.toFormat('yyyy-MM-dd');
    let found: boolean = false;
    let current;
    let insertToId;
    for (const phase of phases) {
      for (const activity of phase.activities) {
        current = activity;
        if (!found && startTime <= activity.actStart!) {
          if (current.previousActivityId === undefined || current.phaseDefault && startTime.equals(activity.actStart!)) {
            insertToId = current.id;
          } else {
            insertToId = current.previousActivityId;
          }
          found = true;
        }
      }
    }

    if (!found) {
      insertToId = current?.id!;
    }

    return {insertToId, dir: 'AFTER'};
  }
}
