import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';
import { TranslateModule } from '@ngx-translate/core';
import { AppButtonComponent } from '../../../../../core/components/app-button/app-button.component';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'onboarding-dialog',
    templateUrl: './onboarding-dialog.component.html',
    styleUrls: ['./onboarding-dialog.component.scss'],
    standalone: true,
    imports: [
        MatIcon,
        AppButtonComponent,
        TranslateModule,
    ],
})
export class OnboardingDialogComponent implements OnInit {

  constructor(
    private cookie: CookieService,
    private dialogRef: MatDialogRef<OnboardingDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
    this.cookie.put('first-onboarding-dialog', 'true');
  }
}
