<div class="c-activityListEntry {{priorityPainterClass}}"
    [ngClass]="{'done' : activity.done, 'activeNow': isActiveNowActivity, 'cursor-pointer': !!priorityPainterClass, 'moreInfo': (activityData$ | async)?.id === activity.id}"
    (click)="!!priorityPainterClass ? updatePriority(activity.id, priorityPainterId) : null">
  <div class="c-activityListEntry__create">
    <mat-icon class="c-activityListEntry__createIcon" svgIcon="add" (click)="addActivity(true)"></mat-icon>
  </div>
  <div class="c-activityListEntry__create c-activityListEntry__create--bottom">
    <mat-icon class="c-activityListEntry__createIcon" svgIcon="add" (click)="addActivity(false)"></mat-icon>
  </div>

  <button class="c-activityListEntry__checkbox c-activityListEntry__checkbox--gray"
          [class.checked]="activity.done"
          (click)="doneChanged(!activity.done)">
          <mat-icon class="c-activityListEntry__checkboxIcon">done</mat-icon>
  </button>

  <div class="c-activityListEntry__fields" [class.pointer-events-none]="!!priorityPainterClass">
    <p class="c-activityListEntry__prioritiesItem {{activity?.priority?.description}}"
       [matMenuTriggerFor]="priorityDropdown">
      <span class="c-activityListEntry__prioritiesItemDot"></span>
    </p>

    <mat-menu #priorityDropdown="matMenu" xPosition="before" yPosition="above">
      <div class="c-activityListEntry__prioritiesWrapper">
        <p class="c-activityListEntry__prioritiesTitle">{{'Priority' | translate}}</p>
        <ul class="c-activityListEntry__priorities">
          <li *ngFor="let priority of priorities"
              (click)="updatePriority(activity.id, priority.id)"
              class="c-activityListEntry__prioritiesItem {{priority.description}}"
              [ngClass]="{'active': activity.priority.id === priority.id}">
            <span class="c-activityListEntry__prioritiesItemDot"></span>
          </li>
        </ul>
      </div>
    </mat-menu>

    <div class="c-activityListEntry__descriptionWrapper">
      <div class="w-full">
        <input #activityDescription
                type="text"
                class="c-activityListEntry__description"
               [placeholder]="'Write your task...' | translate"
               [ngClass]="{'done' : activity.done}"
               (ngModelChange)="descriptionEntering($event)"
               [value]="activity.description"
               [(ngModel)]="activity.description"
               (change)="descriptionChanged()"
               (keyup.enter)="activityDescription.blur()"/>
      </div>

      <span [matMenuTriggerFor]="contactsMenu" #contactsMenuTrigger="matMenuTrigger" class="c-activityListEntry__descriptionContactsTrigger"></span>
      <mat-menu #contactsMenu="matMenu">
        <ng-container *ngIf="contacts.length else contactsEmpty">
          <ul class="c-activityListEntry__descriptionList" (click)="$event.stopPropagation()">
            <li *ngFor="let contact of contacts">
              <p class="c-activityListEntry__descriptionListName">{{contact.displayName}}</p>

              <ng-container *ngIf="isCallKeyWord">
                <p *ngIf="contact.phones.length"
                   class="c-activityListEntry__descriptionListTitle">{{'Phones' | translate}}:</p>
                <ng-container *ngFor="let phone of contact.phones">
                  <a (click)="setActivityCallDescription(activity, contact, phone.value)">{{phone.value}}</a>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="isMailKeyWord">
                <p class="c-activityListEntry__descriptionListTitle">{{'Emails' | translate}}:</p>
                <ng-container *ngFor="let email of contact.emails">
                  <a (click)="setActivityCallDescription(activity, contact, email.value)">{{email.value}}</a>
                </ng-container>
              </ng-container>
            </li>
          </ul>
        </ng-container>
        <ng-template #contactsEmpty>
          <p class="c-activityListEntry__descriptionListEmpty">{{'For query' | translate}} "{{searchPeopleValue}}" {{'nothing found' | translate}}</p>
        </ng-template>
      </mat-menu>

      <div class="flex items-center justify-end w-full">
        <activity-assigned-task
          [activity]="activity"
          [groupsWithTasks]="groupsWithTasks"
          (assignTaskToActivityEmit)="assignTaskToActivity($event)"
          (removeTaskBindingEmit)="removeTaskBinding($event)">
        </activity-assigned-task>
        <activity-entry-info-overlay [activity]="activity"></activity-entry-info-overlay>
      </div>
    </div>

    <duration-entry (durationChanged)="durationChanged($event)" [currentDuration]="activity.duration"></duration-entry>

    <div class="c-activityListEntry__fixedStart" [ngClass]="{'ampm': +timeFormat === 12, 'done' : activity.done}">
      <p class="whitespace-nowrap">{{!!activity.fixedStart ? (activity.fixedStart | dateTimeFormat) : '- - : - -'}}</p>
      <mat-icon svgIcon="schedule"
                class="c-activityListEntry__fixedStartIcon"
                [ngxTimepicker]="picker"
                [ngModel]="activity.fixedStart | dateTimeFormat"
                (ngModelChange)="fixedStartChanged($event)"
                [format]="+timeFormat"
                [value]="activity.fixedStart | dateTimeFormat"></mat-icon>
      <ngx-material-timepicker disableAnimation="true"
                               [cancelBtnTmpl]="cancelBtnTmpl"
                               [confirmBtnTmpl]="confirmBtnTmpl"
                               [theme]="theme"
                               [minutesGap]="5"
                               #picker></ngx-material-timepicker>
      <ng-template #cancelBtnTmpl>
        <app-button btnStyle="outline">{{'Cancel' | translate}}</app-button>
      </ng-template>
      <ng-template #confirmBtnTmpl>
        <app-button class="ml-3 block" style="min-width: 112px;">{{'Set' | translate}}</app-button>
      </ng-template>
      <button *ngIf="!!activity.fixedStart" class="c-activityListEntry__fixedStartClear"
              (click)="clearFixedStartChanged()">
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
    </div>

    <div class="c-activityListEntry__fromTo"
         [ngClass]="{
         'done' : activity.done,
          'failure': (!!activity.timeFailure),
          'ampm': +timeFormat === 12
          }">
      {{activity.actStart | dateTimeFormat}} - {{activity.actEnd | dateTimeFormat}}
    </div>
  </div>

  <button [matMenuTriggerFor]="menu" class="c-activityListEntry__more">
    <mat-icon svgIcon="more-horiz"></mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">
    <button data-testid="clone_activity_button" *ngIf="!activity.appointment" (click)="cloneActivity()" mat-menu-item>
      <mat-icon svgIcon="content-copy"></mat-icon>
      <span>{{'Clone activity' | translate}}</span>
    </button>

    <div (click)="$event.stopPropagation()">
      <div class="c-activityListEntry__prioritiesWrapper">
        <p class="c-activityListEntry__prioritiesTitle">{{'Priority' | translate}}</p>
        <ul class="c-activityListEntry__priorities">
          <li *ngFor="let priority of priorities"
              (click)="updatePriority(activity.id, priority.id)"
              class="c-activityListEntry__prioritiesItem {{priority.description}}"
              [ngClass]="{'active': activity.priority.id === priority.id}">
            <span class="c-activityListEntry__prioritiesItemDot"></span>
          </li>
        </ul>
      </div>
    </div>

    <a *ngIf="!!activity.appointment"
       [href]="activity.appointment.extLink"
       target="_blank" rel="noopener noreferrer nofollow" mat-menu-item>
      <mat-icon>link</mat-icon>
      <span>{{'Open link' | translate}}</span>
    </a>

    <button (click)="deleteActivity()" mat-menu-item>
      <mat-icon svgIcon="delete"></mat-icon>
      <span>{{(!activity.task || !!activity.task?.is_implicit ? 'Delete activity' : 'Remove activity') | translate}}</span>
    </button>

    <button (click)="moveToClipboard()" mat-menu-item>
      <mat-icon>content_paste_go</mat-icon>
      <span>{{'Move to clipboard' | translate}}</span>
    </button>
  </mat-menu>
</div>
