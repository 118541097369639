import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { AssignedGroupContainer } from '../helpers/planning-board.helper';

@Injectable({providedIn: 'root'})
export class FetchActiveGroupsWithActiveTasksGQL extends Query<AssignedGroupContainer[]> {
  document = gql`
    query Groups {
        groups(where: {status: {_eq: "ACTIVE"}}, order_by: {title: asc}) {
        id
        title
        status
        background
        tasks(where: {
            _or:[
                {status: {_eq: "IN PROGRESS"}},
                {status: {_eq: "TODO"}}
            ]
        }, order_by: {order_in_group: asc}) {
            id
            description
            status
        }
        }
    }
  `;
}
