import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({providedIn: 'root'})
export class UpdateActivityPriorityGQL extends Mutation {
  document = gql`
  mutation updateActivityPriority($id: bigint = "", $priorityId: bigint = "") {
      update_activities_by_pk(pk_columns: {id: $id}, _set: {priorityId: $priorityId}) {
        priority {
          id
          description
        }
      }
    }
  `;
}
