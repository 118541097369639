<div class="c-dialog">
    <button class="c-dialog__close" matDialogClose><mat-icon svgIcon="close"></mat-icon></button>

    <div class="c-dialog__body">
        <p class="c-dialog__title">{{'Add template for day' | translate}}</p>

        <mat-form-field appearance="outline" data-testid="templates_combobox" class="w-full">
            <mat-label>{{'Select Template' | translate}}</mat-label>
            <mat-select [formControl]="selectedTemplateId">
                <mat-option *ngFor="let template of data.templates" [value]="template.id">
                    {{template.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="selectedTemplateId.hasError('required')">
                {{'Please, select template for day' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="c-dialog__footer">
        <div class="c-dialog__footerBtns">
            <app-button data-testid="cancel_button" btnStyle="outline" matDialogClose>{{'Cancel' |
                translate}}</app-button>
            <app-button data-testid="save_phase_from_template_btn" (click)="onOk()">{{'Save' | translate}}</app-button>
        </div>
    </div>
</div>