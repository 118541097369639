import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateEwsConfigGQL extends Mutation {
  document = gql`
    mutation update_ews_config_by_pk($id: uuid!, $ews_url: String, $ews_user: String, $ews_password: String, $ews_exchange_version: String) {
      update_ews_config_by_pk(pk_columns: {id: $id}, _set: {ews_user: $ews_user, ews_url: $ews_url, ews_password: $ews_password, ews_exchange_version: $ews_exchange_version}) {id}
    }`;
}
